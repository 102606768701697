import type { FC } from 'react'

import { Input } from 'components'
import { EInput, ESizes } from 'types'

import type { IStep } from './types'
import styles from './Register.module.scss'

const StepOne: FC<IStep> = ({ register, errors }) => (
  <>
    <Input
      placeholder='EnterEmailAddress'
      type={EInput.EmailType}
      size={ESizes.Large}
      label='Email'
      name='email'
      register={register}
      error={errors?.email?.message}
      containerClass={styles.wrapper__email}
    />

    <div className={styles.wrapper__steps__row_wrap}>
      <Input
        placeholder='FirstName'
        type={EInput.TextType}
        size={ESizes.Large}
        name='firstName'
        label='FirstName'
        register={register}
        error={errors?.firstName?.message}
      />

      <Input
        placeholder='LastName'
        type={EInput.TextType}
        size={ESizes.Large}
        name='lastName'
        label='LastName'
        register={register}
        error={errors?.lastName?.message}
      />
    </div>
  </>
)

export default StepOne
