import { createSlice } from '@reduxjs/toolkit'

import type { TContentState } from './types'
import { getSubscriberStats, getVideos } from './actions'

const initialState: TContentState = {
  subscribers: {
    data: null,
    error: null,
    loading: false,
  },
  videos: {
    data: null,
    error: null,
    loading: false,
  },
}

const ContentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    clearVideos: state => {
      if (state.videos.data) {
        state.videos.data.items = []
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getSubscriberStats.pending, state => {
      state.subscribers.loading = true
      state.subscribers.error = null
    })
    builder.addCase(getSubscriberStats.fulfilled, (state, action) => {
      state.subscribers.loading = false
      state.subscribers.error = null
      state.subscribers.data = action.payload
    })
    builder.addCase(getSubscriberStats.rejected, (state, action) => {
      state.subscribers.loading = false
      state.subscribers.error = action.payload as null
    })

    builder.addCase(getVideos.pending, state => {
      state.videos.loading = true
      state.videos.error = null
    })
    builder.addCase(getVideos.fulfilled, (state, action) => {
      state.videos.loading = false
      state.videos.error = null

      if (action.payload.items && action.payload.items.length > 0) {
        state.videos.data = {
          ...action.payload,
          items: [...(state.videos.data?.items ?? []), ...action.payload.items],
        }
      } else {
        state.videos.data = action.payload
      }
    })
    builder.addCase(getVideos.rejected, (state, action) => {
      state.videos.loading = false
      state.videos.error = action.payload as null
    })
  },
})

export const { clearVideos } = ContentSlice.actions

const contentSliceReducer = ContentSlice.reducer

export default contentSliceReducer
