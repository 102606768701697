import { LegacyRef, forwardRef } from 'react'
import classNames from 'classnames'

import { ModeSwitch, SidebarMenu, SidebarFooter, LangDropdown, AuthComponent } from 'components'

import type { TMobileSidebarProps } from './types'
import styles from './MobileSidebar.module.scss'

const MobileSidebar = forwardRef(
  ({ isOpen = false, setOpen, sidebarMenuItems, modalState }: TMobileSidebarProps, ref: LegacyRef<HTMLDivElement>) => {
    const sidebarWrapperClassName = classNames(styles.container, {
      [styles.container_closed]: !isOpen,
    })

    return (
      <nav ref={ref} className={sidebarWrapperClassName}>
        <div className={styles.container__wrapper}>
          <div className={styles.container__header}>
            <div className={styles.container__header__lang}>
              <ModeSwitch />
            </div>
          </div>

          <div className={styles.wrapper__row}>
            <LangDropdown />

            <AuthComponent modalState={modalState} />
          </div>

          <SidebarMenu
            open={isOpen}
            setOpen={setOpen}
            modalState={modalState}
            className={styles.container__menu}
            sidebarMenuItems={sidebarMenuItems}
          />
        </div>

        <SidebarFooter isHaveSupportButton={false} open={isOpen} />
      </nav>
    )
  }
)

export default MobileSidebar
