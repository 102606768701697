import type { FC } from 'react'
import classNames from 'classnames'

import { ESizes } from 'types'
import { Link, Text } from 'components'
import type { THome } from 'components/molecules/types'
import { AmatuniPrimaryIcon, ArrowRightIcon } from 'assets'

import { academyIcons } from './utils'
import styles from './DiscoverAcademy.module.scss'

const DiscoverAcademy: FC<THome> = ({ className }) => {
  const renderIcons = academyIcons.map((element, index) => (
    <img src={element} key={index} width={28} height={28} alt='Academy lesson icon' className={styles.wrapper__icon} />
  ))

  return (
    <div className={classNames(className, styles.wrapper)}>
      <div className={styles.wrapper__left}>
        <div className={styles.wrapper__icons}>{renderIcons}</div>

        <div className={styles.wrapper__left__container}>
          <div className={styles.wrapper__description}>
            <h4 className={styles.wrapper__description__title}>Saorsa Academy</h4>

            <Text className={styles.wrapper__description__subtitle} text='AboutSaorsaAcademy' />
          </div>

          <div className={styles.wrapper__left__footer}>
            <Link
              outsideNavigate
              size={ESizes.Large}
              RightIcon={ArrowRightIcon}
              navigateTo='https://saorsa.academy/'
              className={styles.wrapper__left__button}
            >
              Discover
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.wrapper__right}>
        <AmatuniPrimaryIcon />
      </div>
    </div>
  )
}

export default DiscoverAcademy
