import type { FC } from 'react'
import classNames from 'classnames'

import { EButtonVariants, ESizes } from 'types'
import { Button, Modal, Text } from 'components'
import { useAppDispatch } from 'libraries/redux'
import { getProfileData } from 'store/user/actions'
import { SecurityIcon, DisableSecurityIcon } from 'assets'
import { clearTwoFactorAuthToggle } from 'store/auth/actions'

import type { ITwoFactorMessageProps } from './types'
import styles from './TwoFactorMessageModal.module.scss'

const TwoFactorMessageModal: FC<ITwoFactorMessageProps> = ({ isEnabled, open, onClose }) => {
  const dispatch = useAppDispatch()

  const subtitle = isEnabled ? 'TwoFactorEnabled' : 'TwoFactorDisabled'

  const closeHandler = () => {
    dispatch(clearTwoFactorAuthToggle())
    dispatch(getProfileData())

    onClose()
  }

  return (
    <Modal closable open={open} onClose={onClose} titleClassName={styles.wrapper__icon} className={styles.wrapper}>
      <Text
        text={subtitle}
        className={classNames(styles.wrapper__subtitle, { [styles.wrapper__subtitle__enabled]: isEnabled })}
        LeftIcon={isEnabled ? SecurityIcon : DisableSecurityIcon}
      />

      <Button size={ESizes.Large} onClick={closeHandler} variant={EButtonVariants.Tertiary}>
        Close
      </Button>
    </Modal>
  )
}

export default TwoFactorMessageModal
