export const languagesDropdownUtils = (isEnglish: boolean) => [
  {
    lang: 'en',
    text: 'ENG',
    styleCondition: isEnglish,
  },
  {
    lang: 'ru',
    text: 'РУС',
    styleCondition: !isEnglish,
  },
]
