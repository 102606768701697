import type { ChangeEvent } from 'react'

import type { useStateType } from 'types'

export const handleFileSelect = async (
  event: ChangeEvent<HTMLInputElement> | any,
  setImage: useStateType<string>,
  setShowModal: useStateType<boolean>
) => {
  try {
    event.preventDefault()
    let files: FileList | null = null

    if (event.dataTransfer && event.dataTransfer.files) {
      files = event.dataTransfer.files
    } else if (event.target && event.target.files) {
      files = event.target.files
    }

    if (!files) return

    const reader = new FileReader()

    reader.onload = () => {
      setImage(reader.result as string)
      if (event.target instanceof HTMLInputElement) {
        event.target.value = ''
      }
      setShowModal(true)
    }

    reader.readAsDataURL(files[0])
  } catch (error) {}
}
