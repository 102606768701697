import { type FC, useState, Fragment } from 'react'
import { noop } from 'lodash'
import { sub } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { ErrorIcon } from 'assets'
import { useAuthentication } from 'hooks'
import { setCookie } from 'libraries/cookie'
import { multipartDatePickerValue } from 'utils'
import { UserSelectors } from 'store/user/selectors'
import { EButtonVariants, ESizes, TDate } from 'types'
import { updateUserInformation } from 'store/user/actions'
import { useAppDispatch, useAppSelector } from 'libraries/redux'
import { ToastVersions, showNotification } from 'libraries/toastify'
import { Button, DatePicker, DayPicker, DividerWithText, Loader, Modal, Text, Tooltip } from 'components'

import { defaultDate } from '../Register/utils'

import type { TBirthdayRequiredModalProps } from './types'
import styles from './BirthdayRequiredModal.module.scss'

const BirthdayRequiredModal: FC<TBirthdayRequiredModalProps> = ({ open, closeHandler, isUserBirthday = false }) => {
  const dispatch = useAppDispatch()

  const { handleLogout } = useAuthentication()
  const { loading } = useAppSelector(UserSelectors.getUser)
  const { data: userData } = useAppSelector(UserSelectors.getProfile)

  const [dateValue, setDateValue] = useState<TDate>(defaultDate)
  const subtractedDate = sub(new Date(), { years: 18, days: 1 })
  const maxDays =
    Number(dateValue?.year) === subtractedDate.getFullYear() &&
    Number(dateValue?.month) - 1 === subtractedDate.getMonth()
      ? subtractedDate.getDate()
      : undefined
  const maxMonths = Number(dateValue?.year) === subtractedDate.getFullYear() ? subtractedDate : undefined

  const {
    reset,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<any>({
    mode: 'onChange',
  })

  const birthdayFormat = multipartDatePickerValue(dateValue?.year, dateValue?.month, dateValue?.day)
  const isDateInvalid = birthdayFormat.includes('InvalidDate') ? birthdayFormat : null

  const isValidationBirthDay = Boolean(birthdayFormat) && Boolean(!isDateInvalid) && isValid

  const changeDateValueHandler = (key: string, value: string) => {
    const isMonth = key === 'month' ? 1 + value : value
    const lessThanTen = Number(value) < 10 ? `0${isMonth}` : isMonth

    setDateValue({
      ...dateValue,
      [key]: lessThanTen,
    })
  }

  const lockOutsideClickHandler = () => {
    showNotification(ToastVersions.info, 'AddYoureBirthdayRequired')
  }

  const closeModalHandler = () => {
    closeHandler()
    handleLogout()
  }

  const onSubmit = () => {
    setCookie('isNewUser', '1')

    const requestPayload = {
      ...userData,
      birthday: birthdayFormat,
    }

    if (isValidationBirthDay) {
      dispatch(updateUserInformation(requestPayload))
    }
    setDateValue(defaultDate)
    reset()
  }

  return (
    <Modal
      onClose={noop}
      closable={false}
      open={open && isUserBirthday}
      contentClassName={styles.wrapper}
      title={loading ? '' : 'PleaseAddYourBirthday'}
      lockOutsideClickHandler={lockOutsideClickHandler}
    >
      {loading ? (
        <Loader size='150px' className={styles.wrapper__loading} />
      ) : (
        <Fragment>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.wrapper__select}>
              <div className={styles.wrapper__row}>
                <Text text='SelectBirthday' className={styles.wrapper__select__title} />

                {isDateInvalid ? <Tooltip isError isShowFromStart title={isDateInvalid} Icon={ErrorIcon} /> : null}
              </div>

              <div className={styles.wrapper__select__birthday}>
                <Controller
                  name='birthday'
                  control={control}
                  defaultValue={birthdayFormat}
                  render={() => {
                    return (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DayPicker
                          maxDate={maxDays}
                          placeholder='Day'
                          dateValue={dateValue}
                          setValue={setDateValue}
                          inputSize={ESizes.Large}
                          year={dateValue?.year ?? ''}
                          month={dateValue?.month ?? ''}
                        />

                        <DatePicker
                          format='MM'
                          views={['month']}
                          placeholder='Month'
                          maxDate={maxMonths}
                          onChange={(e: any) => changeDateValueHandler('month', e?.getMonth() ?? 0)}
                        />

                        <DatePicker
                          views={['year']}
                          placeholder='Year'
                          maxDate={subtractedDate}
                          minDate={new Date('1950-01-01')}
                          onChange={(e: any) => changeDateValueHandler('year', e?.getFullYear() ?? 0)}
                        />
                      </LocalizationProvider>
                    )
                  }}
                />
              </div>
            </div>

            <Button
              type='submit'
              size={ESizes.Large}
              disabled={!isValidationBirthDay}
              variant={EButtonVariants.Primary}
            >
              Save
            </Button>
          </form>

          <DividerWithText text='OR' className={styles.divider} />

          <Button size={ESizes.Large} variant={EButtonVariants.Tertiary} onClick={closeModalHandler}>
            LogOut
          </Button>
        </Fragment>
      )}
    </Modal>
  )
}

export default BirthdayRequiredModal
