import { useCallback, type FC, useEffect } from 'react'
import { isEmpty, isNil, noop } from 'lodash'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { CloseIcon } from 'assets'
import { resetPasswordScheme } from 'utils'
import { setAuthModalType } from 'store/global'
import { Button, Text, Input } from 'components'
import { resetPassword } from 'store/auth/actions'
import { AuthSelectors } from 'store/auth/selectors'
import { useAppDispatch, useAppSelector } from 'libraries/redux'
import { EAuth, EButtonVariants, EInput, ESizes } from 'types'

import type { TAuthModalProps } from '../types'
import type { TResetPasswordFormData } from './types'
import styles from './ResetPassword.module.scss'

const ResetPassword: FC<TAuthModalProps> = ({
  onClose = noop,
  email,
  newPasswordToken,
  openSuccessSaveModal = noop,
}) => {
  const dispatch = useAppDispatch()

  const { loading, error } = useAppSelector(AuthSelectors.getResetPassword)

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted, isDirty },
  } = useForm<TResetPasswordFormData>({
    mode: 'onChange',
    resolver: yupResolver(resetPasswordScheme) as any,
  })

  const handleClose = useCallback(() => {
    onClose()
    reset()
  }, [onClose, reset])

  const onSubmit = useCallback(
    (formData: TResetPasswordFormData) => {
      dispatch(
        resetPassword({
          newPasswordToken,
          newPassword: formData.newPassword,
          newPasswordConfirm: formData.newPasswordConfirm,
        })
      )
    },
    [dispatch, newPasswordToken]
  )

  const onLoginClick = () => {
    dispatch(setAuthModalType(EAuth.Login))
  }

  useEffect(() => {
    if (!loading && isNil(error) && isSubmitted && isEmpty(errors)) {
      handleClose()
      openSuccessSaveModal()
    }
  }, [loading, error, isSubmitted, handleClose, errors, openSuccessSaveModal])

  return (
    <div className={styles.wrapper__column}>
      <div className='auth_modal_title'>
        <div className='auth_modal_title__container'>
          <Text text='CreateANewPassword' tagName='h4' className='auth_modal_title__container__text' />

          <CloseIcon onClick={onClose} />
        </div>

        <p className='auth_modal_title__container__text'>
          <Text emptyTag text='PasswordRecover' /> {email} <Text emptyTag text='Email' />.
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
        <div className={styles.wrapper__inputs}>
          <Input
            placeholder='EnterPassword'
            type={EInput.PasswordType}
            size={ESizes.Large}
            name='newPassword'
            register={register}
            error={errors?.newPassword?.message}
          />
          <Input
            placeholder='ConfirmPassword'
            type={EInput.PasswordType}
            size={ESizes.Large}
            name='newPasswordConfirm'
            register={register}
            error={errors?.newPasswordConfirm?.message}
          />
        </div>

        <div className={styles.wrapper__container}>
          <Button
            type='submit'
            isLoading={loading}
            size={ESizes.Large}
            disabled={!isValid && isDirty}
            variant={EButtonVariants.Primary}
          >
            ResetPassword
          </Button>

          <Text role='button' className={styles.wrapper__link} onClick={onLoginClick} text='ReturnToSignIn' />
        </div>
      </form>
    </div>
  )
}

export default ResetPassword
