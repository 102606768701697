import { useWindowSize } from '@react-hook/window-size'

// @TODO refactor sizes

const useResponsive = () => {
  const [width] = useWindowSize()

  const isLarge = width < 1600

  const isWideL = width < 1440

  const isHD = width < 1366

  const isWide = width < 1256

  const isLaptop = width < 1024

  const isTabletL = width < 992

  const isTablet = width < 876

  const isTabletS = width < 768

  const isMobileM = width < 576

  const isMobile = width < 480

  const isMobileS = width < 376

  return {
    isHD,
    isWide,
    isWideL,
    isLarge,
    isTabletS,
    isLaptop,
    isTablet,
    isMobile,
    isMobileS,
    isMobileM,
    isTabletL,
  }
}

export default useResponsive
