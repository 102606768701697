export const detectTokenSettings = (currentAuthCookie: string, refreshToken?: string, web3RefreshToken?: string) => {
  switch (currentAuthCookie) {
    case 'web2': {
      return {
        tokenName: 'token',
        refresh: refreshToken,
      }
    }

    case 'web3': {
      return {
        tokenName: 'token_web3',
        refresh: web3RefreshToken,
      }
    }

    default:
      return { tokenName: '', refresh: '' }
  }
}
