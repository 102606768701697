import { isEmpty } from 'lodash'
import axios, { type AxiosRequestConfig } from 'axios'

import { COOKIE_EXPIRATION_TIME, detectTokenSettings } from 'utils'
import { getCookie, removeCookie, setCookie } from 'libraries/cookie'

const defaultOptions = {
  baseURL: process.env.REACT_APP_BASE_URL,
}

const axiosInstance = axios.create(defaultOptions)

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const selectedLanguage = localStorage.getItem('i18nextLng') || 'en'

    const web2Token = getCookie('token')
    const currentAuthCookie = getCookie('current_auth') as string
    const userCookie = getCookie('user') ? JSON.parse(String(getCookie('user')) ?? '') : null

    const { tokenName } = detectTokenSettings(currentAuthCookie)

    const currentAuthToken = getCookie(tokenName)
    const twoFacEnable = !isEmpty(web2Token) && isEmpty(userCookie)

    const authToken = twoFacEnable ? web2Token : currentAuthToken

    config.headers = {
      Authorization: `Bearer ${authToken}`,
      'x-custom-lang': selectedLanguage,
    }

    return config
  },

  error => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  config => Promise.resolve(config),
  async error => {
    const originalRequest = error.config

    const refreshToken = getCookie('refreshToken') as string
    const currentAuthCookie = getCookie('current_auth') as string
    const web3RefreshToken = getCookie('web3RefreshToken') as string

    // @TODO-_-CONTINUE: Change all refreshToken names to `(${web2 || web3}RefreshToken)
    // const refreshToken = getCookie(currentAuthCookie + 'refreshToken')

    const { tokenName, refresh } = detectTokenSettings(currentAuthCookie, refreshToken, web3RefreshToken)

    // @TODO-_-in my opinion it should work for web_3   { Need to check for web_3}
    if (error.response.status === 401 && Boolean(refresh)) {
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/refresh-token`, {
          refreshToken: refresh,
        })
        setCookie(tokenName, data?.data?.accessToken, COOKIE_EXPIRATION_TIME)

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${data?.data?.accessToken}`

        return axiosInstance(originalRequest)
      } catch (err: any) {
        removeCookie(tokenName)
        removeCookie('user')
        removeCookie('current_auth')
        removeCookie('refreshToken')
        removeCookie('is_google_auth')

        location.replace('/')

        return Promise.reject(error)
      }
    }

    return Promise.reject(error)
  }
)

export default axiosInstance
