import { ReactNode, useState, type FC } from 'react'
import classNames from 'classnames'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'

import { Modal, Text } from 'components'
import { useResponsive } from 'hooks'
import { PlayIcon, PositiveIcon, ShowIcon } from 'assets'

import type { TVideoCard } from './types'
import styles from './VideoCard.module.scss'

const VideoCard: FC<TVideoCard> = ({ videoData, className }) => {
  const { title, image, views, likes, videoId } = videoData

  const { isTablet } = useResponsive()

  const [isOpen, setOpen] = useState<boolean>(false)

  const handleModalOpen = () => {
    setOpen(true)
  }

  const handleModalClose = () => {
    setOpen(false)
  }

  const Container: FC<ReactNode | any> = ({ children }) =>
    isTablet ? (
      <a
        href={`https://www.youtube.com/watch?v=${videoId}`}
        target='_blank'
        className={classNames(className, styles.wrapper)}
      >
        {children}
      </a>
    ) : (
      <div role='button' onClick={handleModalOpen} className={classNames(className, styles.wrapper)}>
        {children}
      </div>
    )

  return (
    <>
      <Container>
        <div className={styles.wrapper__image__container}>
          <img width='100%' height='100%' alt='Video image' src={image} className={styles.wrapper__image} />
        </div>

        <div className={styles.wrapper__container}>
          <div className={styles.wrapper__container__hidden}>
            <div className={styles.wrapper__container__play}>
              <div className={styles.wrapper__container__play__icon}>
                <PlayIcon />
              </div>
            </div>

            <div className={styles.wrapper__stats}>
              <Text LeftIcon={ShowIcon} className={styles.wrapper__stats__text} text={`${views}`} />
              <Text text={`${likes}`} LeftIcon={PositiveIcon} className={styles.wrapper__stats__text} />
            </div>
          </div>

          <Text className={styles.wrapper__text} text={title} />
        </div>
      </Container>

      <Modal open={isOpen} title={title} className={styles.wrapper__modal} onClose={handleModalClose}>
        <LiteYouTubeEmbed id={videoId} title={title} iframeClass={styles.wrapper__iframe} />
      </Modal>
    </>
  )
}

export default VideoCard
