import { object, string } from 'yup'

const resetPasswordScheme = object().shape({
  newPassword: string()
    .required('passwordRequired')
    .matches(/^[^\s]+$/, 'passwordWithoutWhitespace')
    .min(6, 'passwordMinLength')
    .max(20, 'passwordMaxLength')
    .matches(
      /^(?=(?:.*[A-Z]){1,})(?=(?:.*[a-z]){1,})(?=(?:.*\d){1,})(?=(?:.*[!@#$%^&*().\-_=+{};:,<.>]){1,})([A-Za-z0-9!@#$%^&*()\-_=+{};:,<.>]{6,20})/,
      'passwordValidate'
    ),
  newPasswordConfirm: string()
    .required('passwordConfirmRequired')
    .test('passwords-match', 'passwordMustMatch', function (value) {
      return this.parent.newPassword === value
    }),
})

export default resetPasswordScheme
