import type { FC } from 'react'

import { Button } from 'components'
import { EButtonVariants, ESizes } from 'types'

import type { TTwoFactorFooterProps } from './types'
import styles from './TwoFactorModal.module.scss'

const TwoFactorFooter: FC<TTwoFactorFooterProps> = ({
  disabled,
  isLoading,
  cancelHandler,
  verifyHandler,
  required2FacField,
}) => (
  <div className={styles.submitting}>
    <Button
      size={ESizes.Medium}
      onClick={cancelHandler}
      className={styles.submitting_btn}
      variant={EButtonVariants.Tertiary}
    >
      {required2FacField ? 'LogOut' : 'TryLater'}
    </Button>

    <Button
      disabled={disabled}
      size={ESizes.Medium}
      onClick={verifyHandler}
      isLoading={isLoading}
      className={styles.submitting_btn}
      variant={EButtonVariants.Primary}
    >
      Verify
    </Button>
  </div>
)

export default TwoFactorFooter
