import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'

import SidebarMenuItem from './SidebarMenuItem'
import type { ISidebarMenuSectionProps } from '../types'
import styles from './SidebarMenu.module.scss'

const SidebarSection: FC<ISidebarMenuSectionProps> = ({ open, setOpen, title, menuItems }) => {
  const renderItems = menuItems.map(({ text, navigateTo, soon, Icon }) => (
    <SidebarMenuItem setOpen={setOpen} Icon={Icon} soon={soon} navigateTo={navigateTo} text={text} open={open} />
  ))

  return (
    <div className={classNames({ [styles.wrapper__closed]: !open })}>
      {title && (
        <div
          className={classNames({
            [styles.wrapper__title]: open,
            [styles.wrapper__closed__title]: !open,
          })}
        >
          {open && <Text tagName='span' text={title} />}
        </div>
      )}

      <div>{renderItems}</div>
    </div>
  )
}

export default SidebarSection
