import type { FC } from 'react'
import { styled } from '@mui/material/styles'
import { FormControlLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'

import type { TFormControlLabelProps } from './types'

const FormControlLabelMui: FC<TFormControlLabelProps> = ({ value, control, active, label }) => {
  const { t } = useTranslation()

  const FormControlLabelComponent = styled(FormControlLabel)<TFormControlLabelProps>(({ active }) => ({
    margin: 0,

    '.MuiButtonBase-root': {
      padding: 0,
      border: '1px',
      marginRight: '8px',
      color: active ? '#0095FA' : '#8793A0',
    },

    '.MuiFormControlLabel-label': {
      color: '#8793A0',
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '24px',
      fontFamily: 'Rubik',
    },
  }))

  return <FormControlLabelComponent control={control} label={t(String(label))} active={active} value={value} />
}

export default FormControlLabelMui
