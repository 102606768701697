import type { FC } from 'react'

import { Modal, Button } from 'components'
import { ESizes, EButtonVariants } from 'types'

import type { TTwoFactorRecommendationProps } from './types'
import styles from './TwoFactorRecommendation.module.scss'

const TwoFactorRecommendation: FC<TTwoFactorRecommendationProps> = ({ open, onClose, enableHandler }) => (
  <Modal open={open} onClose={onClose} title='DoYouEnableTwoFactorAuthentication' contentClassName={styles.container}>
    <Button size={ESizes.Large} variant={EButtonVariants.Tertiary} onClick={onClose}>
      Skip
    </Button>

    <Button size={ESizes.Large} variant={EButtonVariants.Primary} onClick={enableHandler}>
      Enable
    </Button>
  </Modal>
)

export default TwoFactorRecommendation
