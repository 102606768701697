import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'
import { DiscountIcon, SoonIcon } from 'assets'
import { EComingSoonItemVariants } from 'types'

import type { TComingSoonItemProps } from './types'
import styles from './ComingSoonItem.module.scss'

const ComingSoonItem: FC<TComingSoonItemProps> = ({
  closed,
  className = '',
  text,
  Icon,
  variant,
  isDynamic = false,
  day,
}) => {
  const isSoon = variant === EComingSoonItemVariants.Soon
  const isPreSale = variant === EComingSoonItemVariants.Presale
  const isDaysLeft = variant === EComingSoonItemVariants.Days
  const isIconSoon = (isSoon || isDaysLeft) && !Icon ? <SoonIcon /> : null
  const isIconDiscount = isPreSale && !Icon ? <DiscountIcon /> : null

  const wrapperClassNameAsVariant = () => {
    switch (variant) {
      case EComingSoonItemVariants.Available:
        return styles.wrapper__available

      case EComingSoonItemVariants.Days:
        return styles.wrapper__days

      case EComingSoonItemVariants.Presale:
        return styles.wrapper__presale

      case EComingSoonItemVariants.Soon:
        return styles.wrapper__soon
    }
  }

  const textClassNameAsVariant = () => {
    switch (variant) {
      case 'available':
        return styles.wrapper__available__title

      case 'days':
        return styles.wrapper__days__title

      case 'presale':
        return styles.wrapper__presale__title

      case 'soon':
        return styles.wrapper__soon__title
    }
  }

  return (
    <div
      className={classNames(styles.wrapper, wrapperClassNameAsVariant(), {
        [className]: className,
        [styles.wrapper__closed]: closed,
        [styles.wrapper__dynamic]: isDynamic,
      })}
    >
      {Icon ? <Icon /> : isIconSoon || isIconDiscount}

      {(day || day === 0) && (
        <span className={classNames(styles.wrapper__title, styles.wrapper__days__title)}>{day}</span>
      )}

      <Text
        className={classNames(styles.wrapper__title, textClassNameAsVariant(), {
          [styles.wrapper__title__active]: closed,
          [styles.wrapper__title__dynamic]: isDynamic,
        })}
        text={text!}
      />
    </div>
  )
}

export default ComingSoonItem
