import { Sector } from 'recharts'

import { useResponsive } from 'hooks'

// @TODO need to add types
const ActiveRadius = (props: any) => {
  const { isMobile } = useResponsive()

  const { cx, cy, fill, endAngle, startAngle, outerRadius } = props

  const decreaseNumber = isMobile ? 45 : 65

  return (
    <g>
      <Sector
        cx={cx + 3}
        cy={cy - 1.8}
        fill={fill}
        stroke='#fff'
        strokeWidth={2}
        endAngle={endAngle}
        startAngle={startAngle}
        outerRadius={outerRadius}
        innerRadius={outerRadius - decreaseNumber}
      />
    </g>
  )
}

export default ActiveRadius
