import { type FC, useState, useCallback, useRef } from 'react'
import Cropper from 'react-easy-crop'

import { Button, Modal } from 'components'
import { EButtonVariants, ESizes } from 'types'

import type { TEventPositionType, TRefPositionProps, TAvatarCropProps } from '../types'

import { base64toFile } from './utils'
import styles from './AvatarCrop.module.scss'

const AvatarCrop: FC<TAvatarCropProps> = ({ image, open, setShowModal, onSaveHandler }) => {
  const croppedAreaRef = useRef<TRefPositionProps | any>(null)
  const croppedAreaPixelsRef = useRef<TRefPositionProps | any>(null)

  const [zoom, setZoom] = useState<number>(1)
  const [crop, setCrop] = useState<TEventPositionType>({ x: 0, y: 0 })

  const closeHandler = () => {
    setShowModal(false)
  }

  const onCropComplete = useCallback((croppedArea: TEventPositionType, croppedAreaPixels: TEventPositionType) => {
    croppedAreaRef.current = croppedArea
    croppedAreaPixelsRef.current = croppedAreaPixels
  }, [])

  const cropImageNow = () => {
    const canvas = document.createElement('canvas')

    canvas.width = croppedAreaPixelsRef?.current?.width
    canvas.height = croppedAreaPixelsRef?.current?.height
    const ctx: any = canvas.getContext('2d')
    const imageElement = document.createElement('img')
    imageElement.setAttribute('src', image)
    ctx.drawImage(
      imageElement,
      croppedAreaPixelsRef.current?.x,
      croppedAreaPixelsRef.current?.y,
      croppedAreaPixelsRef.current?.width,
      croppedAreaPixelsRef.current?.height,
      0,
      0,
      croppedAreaPixelsRef.current?.width,
      croppedAreaPixelsRef.current?.height
    )

    const base64Image = canvas.toDataURL('image/jpeg')

    closeHandler()
    onSaveHandler(base64toFile(base64Image, 'userAvatar', 'image/jpeg'))
  }

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      title='Edit Profile Photo'
      className={styles.wrapper}
      titleClassName={styles.wrapper__title}
      contentClassName={styles.wrapper__content}
      titleContainerClassName={styles.wrapper__title__container}
    >
      <div className={styles.wrapper__content__container}>
        <Cropper
          crop={crop}
          zoom={zoom}
          image={image}
          aspect={4 / 3}
          cropShape='round'
          objectFit='contain'
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          classes={{ containerClassName: styles.wrapper__content__container_cropper }}
        />
      </div>

      <div className={styles.wrapper__container}>
        <div className={styles.wrapper__container__cancel}>
          <Button variant={EButtonVariants.Tertiary} size={ESizes.Large} onClick={closeHandler}>
            Cancel
          </Button>
        </div>
        <div className={styles.wrapper__container__block}>
          <div className={styles.wrapper__container__block__rotate}></div>
          <div className={styles.wrapper__container__block__save}>
            <Button variant={EButtonVariants.Primary} size={ESizes.Large} onClick={cropImageNow}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AvatarCrop
