import type { FC } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { LogoutIcon } from 'assets'
import { useAuthentication } from 'hooks'
import { EButtonVariants, ESizes } from 'types'
import { useAppSelector } from 'libraries/redux'
import { Button, ImageComponent } from 'components'
import { UserSelectors } from 'store/user/selectors'
import { ERoutePaths } from 'libraries/router/types'

import type { TAuthComponentProps } from './types'
import styles from './AuthComponent.module.scss'

const AuthComponent: FC<TAuthComponentProps> = ({ modalState }) => {
  const navigate = useNavigate()

  const { data } = useAppSelector(UserSelectors.getProfile)
  const { handleLogout, isAuthenticated } = useAuthentication()

  const redirectToMyAccountHandler = () => {
    navigate(ERoutePaths.MyAccount)
  }

  const authComponentContent = isAuthenticated ? (
    <>
      <Button
        size={ESizes.Small}
        onClick={handleLogout}
        RightIcon={LogoutIcon}
        variant={EButtonVariants.Secondary}
        className={styles.container__logout}
      />

      <button onClick={redirectToMyAccountHandler} className={styles.container__image}>
        <ImageComponent
          type='user'
          width='32px'
          height='32px'
          loading='eager'
          alt='User image'
          src={data?.avatar}
          className={styles.container__image_img}
        />
      </button>
    </>
  ) : (
    <>
      <Button size={ESizes.Large} variant={EButtonVariants.Quaternary} onClick={modalState.openLoginModal}>
        SignIn
      </Button>

      <Button size={ESizes.Large} variant={EButtonVariants.Secondary} onClick={modalState.openRegisterModal}>
        SignUp
      </Button>
    </>
  )

  return (
    <div
      className={classNames(styles.container, {
        [styles.container_is_auth]: isAuthenticated,
      })}
    >
      {authComponentContent}
    </div>
  )
}

export default AuthComponent
