import { EProgram } from 'types'
import {
  PdfIcon,
  ExamIcon,
  TestIcon,
  VideoIcon,
  TopicIcon,
  ExpertIcon,
  FreeLessonIcon,
  MasterSecondaryIcon,
  BachelorSecondaryIcon,
  ExpertSecondaryIcon,
  ProfessorSecondaryIcon,
  NewsIcon,
  FreeLessonsSecondaryIcon,
  LibraryIcon,
} from 'assets'

export const programInfoIconMapper = {
  [EProgram.Master]: MasterSecondaryIcon,
  [EProgram.Expert]: ExpertSecondaryIcon,
  [EProgram.Bachelor]: BachelorSecondaryIcon,
  [EProgram.Professor]: ProfessorSecondaryIcon,
  [EProgram.Free]: FreeLessonsSecondaryIcon,

  // eslint-disable-next-line lines-around-comment
  // @TODO: Need to change codebase, FreeLesson, News and Blog not using in this object
  [EProgram.FreeLesson]: FreeLessonIcon,
  [EProgram.News]: FreeLessonIcon,
  [EProgram.Blog]: FreeLessonIcon,
}

export const comingSoonMapper = {
  news: NewsIcon,
  library: LibraryIcon,
}
export const comingSoonDescriptionMapper = {
  [EProgram.FreeLesson]: 'ProgramInfoText',
  [EProgram.Free]: 'ProgramInfoText',
  [EProgram.Bachelor]: 'ComingSoonDescriptionBachelor',
  [EProgram.Expert]: 'ComingSoonDescriptionExpert',
  [EProgram.Master]: 'ComingSoonDescriptionMaster',
  [EProgram.Professor]: 'ComingSoonDescriptionProfessor',
  [EProgram.News]: 'ComingSoonDescriptionNews',
  [EProgram.Blog]: 'ComingSoonDescriptionBlog',
}

export const lessonIntroBlockMapper = {
  1: EProgram.Bachelor,
  2: EProgram.Expert,
  3: EProgram.Master,
  4: EProgram.Professor,
  5: EProgram.Free,
}

export const lessonMaterialIconMapper = {
  1: TopicIcon,
  2: VideoIcon,
  3: TestIcon,
  4: ExamIcon,
  5: ExpertIcon,
  6: PdfIcon,
}
