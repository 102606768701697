import { type FC, useEffect, useState } from 'react'
import classNames from 'classnames'

import { timeLeft } from 'utils'

import Time from './Time'
import type { TTimerComponentProps } from './types'
import styles from './TimerComponent.module.scss'

const TimerComponent: FC<TTimerComponentProps> = ({ date, isPreSale = false, className = '', itemClassName = '' }) => {
  const [timeInfo, setTimeInfo] = useState(timeLeft(date))

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeInfo(timeLeft(date))
    }, 1000)

    return () => clearTimeout(timer)
  })

  return (
    <div
      role='button'
      onClick={() => timeLeft(date)}
      className={classNames(styles.wrapper, { [className]: className, [styles.pre_sale]: isPreSale })}
    >
      {timeInfo.map((element, index) => (
        <Time className={itemClassName} isPreSale={isPreSale} date={element.time} key={index} name={element.name} />
      ))}
    </div>
  )
}
export default TimerComponent
