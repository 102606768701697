import { LegacyRef, forwardRef } from 'react'

import SidebarMenu from './SidebarMenu'
import SidebarFooter from './SidebarFooter'
import SidebarSwitch from './SidebarSwitch'
import type { TDesktopSidebarProps } from './types'

const DesktopSidebar = forwardRef(
  (
    { className, open, setOpen, sidebarMenuItems, modalState, setActiveWithoutOpening }: TDesktopSidebarProps,
    ref: LegacyRef<HTMLDivElement>
  ) => (
    <nav ref={ref} className={className}>
      <SidebarMenu
        open={open}
        modalState={modalState}
        setOpen={setActiveWithoutOpening}
        sidebarMenuItems={sidebarMenuItems}
      />

      <SidebarFooter open={open} />

      <SidebarSwitch open={open} setOpen={setOpen} />
    </nav>
  )
)

export default DesktopSidebar
