import { useEffect, type FC } from 'react'
import classNames from 'classnames'
import { Skeleton } from '@mui/material'
import { QRCode } from 'react-qrcode-logo'

import { useCopyText } from 'hooks'
import { Button, Text } from 'components'
import { EButtonVariants, ESizes } from 'types'
import { CopyIcon, TickPrimaryIcon } from 'assets'
import { AuthSelectors } from 'store/auth/selectors'
import { twoFactorQRGenerate } from 'store/auth/actions'
import { useAppDispatch, useAppSelector } from 'libraries/redux'

import styles from './TwoFactorModal.module.scss'

const QrPart: FC = () => {
  const dispatch = useAppDispatch()
  const { data, loading } = useAppSelector(AuthSelectors.postTwoFactorVerify)
  const { isCopied, handleCopyClickChange } = useCopyText(String(data?.secret))

  useEffect(() => {
    dispatch(twoFactorQRGenerate())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.scan}>
      <div className={styles.scan__head}>
        <Text text='ScanQRcode' className={styles.scan__head_title} />

        <div className={styles.scan__head__block} role='button'>
          {loading ? (
            <Skeleton
              width={130}
              height={20}
              variant='rounded'
              className={classNames(styles.scan__head__block_code, 'skeleton__light')}
            />
          ) : (
            <span className={styles.scan__head__block_code}>{data?.secret}</span>
          )}

          <Button
            disabled={loading}
            size={ESizes.Small}
            onClick={handleCopyClickChange}
            variant={EButtonVariants.Tertiary}
            RightIcon={!isCopied ? CopyIcon : TickPrimaryIcon}
            className={classNames(styles.scan__copy, {
              [styles.scan__copy__active]: isCopied,
            })}
          />
        </div>
      </div>

      <div className={styles.scan__qr}>
        {loading ? (
          <Skeleton variant='rounded' width={160} height={160} className='skeleton__light' />
        ) : (
          <QRCode fgColor='black' bgColor='white' value={data?.otpAuthUrl} />
        )}
      </div>
    </div>
  )
}

export default QrPart
