import { useEffect, useState, type UIEvent } from 'react'

const useButtonClickScroll = (scrollableContainerRef: any, itemWidth: number, buttonDeps?: any, scrollDeps?: any) => {
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0)
  const [isButtonDisabled, setButtonDisable] = useState({
    prevButton: false,
    nextButton: false,
  })

  const maxScrollLeft =
    Number(scrollableContainerRef?.current?.scrollWidth) - Number(scrollableContainerRef?.current?.clientWidth)

  const handleNextButtonClick = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft += itemWidth
    }
  }

  const handlePrevButtonClick = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft -= itemWidth
    }
  }

  //Need to give to onScroll event to scrollable container
  const onContentScroll = (event: UIEvent<HTMLDivElement>) => {
    setCurrentScrollPosition(event.currentTarget.scrollLeft)
  }

  useEffect(() => {
    if (scrollDeps) {
      setCurrentScrollPosition(0)
    }
  }, [scrollDeps])

  //Buttons disabled conditions
  useEffect(() => {
    setButtonDisable({
      prevButton: currentScrollPosition === 0 || scrollableContainerRef.current.scrollLeft === 0,
      nextButton: maxScrollLeft - 10 <= currentScrollPosition,
    })
  }, [currentScrollPosition, maxScrollLeft, buttonDeps, scrollableContainerRef])

  const isPrevButtonsDisabled = isButtonDisabled.prevButton
  const isNextButtonsDisabled = isButtonDisabled.nextButton

  return {
    onContentScroll,
    handleNextButtonClick,
    handlePrevButtonClick,
    isPrevButtonsDisabled,
    isNextButtonsDisabled,
  }
}

export default useButtonClickScroll
