import { type FC, Fragment, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Skeleton } from '@mui/material'

import { NoUserPhoto, NoImage } from 'assets'

import type { TImageProps } from './types'

const ImageComponent: FC<TImageProps> = ({
  src,
  alt,
  width,
  height,
  type = 'image',
  className = '',
  loading = 'lazy',
  isLoading = false,
}) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(src)

  useEffect(() => {
    if (!isEmpty(src)) {
      setImageUrl(`${src}?${Math.random()}`)
    }
  }, [src])

  return (
    <div>
      {isLoading ? (
        <Skeleton variant='circular' width={width} height={height} className='skeleton__light' />
      ) : (
        <Fragment>
          {isEmpty(imageUrl) ? (
            type === 'image' ? (
              <NoImage width={width} height={height} className={className} />
            ) : (
              <NoUserPhoto width={width} height={height} className={className} />
            )
          ) : (
            <img
              src={imageUrl}
              width={width}
              height={height}
              loading={loading}
              className={className}
              alt={alt || 'image_alt'}
            />
          )}
        </Fragment>
      )}
    </div>
  )
}

export default ImageComponent
