export const sortPresets = [
  {
    sortField: 'views',
    sortOrder: 'DESC',
  },
  {
    sortField: 'publishedAt',
    sortOrder: 'DESC',
  },
  {
    sortField: 'publishedAt',
    sortOrder: 'ASC',
  },
]
