import { FC, useCallback } from 'react'

import { EmailIcon } from 'assets'
import { Modal, Text } from 'components'
import { forgotPassword } from 'store/auth/actions'
import { AuthSelectors } from 'store/auth/selectors'
import { ForgotPasswordState } from 'store/auth/types'
import { useAppDispatch, useAppSelector } from 'libraries/redux'

import type { TCheckEmailModal } from './types'
import styles from './CheckEmail.module.scss'

const CheckEmailModal: FC<TCheckEmailModal> = ({ open, onClose, openLoginModal }) => {
  const dispatch = useAppDispatch()

  const { data }: ForgotPasswordState = useAppSelector(AuthSelectors.getForgotPassword)

  const onReturnToLogin = useCallback(() => {
    onClose()
    openLoginModal()
  }, [onClose, openLoginModal])

  const handleClick = useCallback(() => {
    if (data) {
      dispatch(forgotPassword(data))
    }
  }, [data, dispatch])

  return (
    <Modal open={open} onClose={onClose} title='CheckYourEmail'>
      <div className={styles.wrapper}>
        <p className={styles.wrapper__label}>
          <Text emptyTag text='PasswordRecover' /> {data?.email} <Text emptyTag text='Email' />.
        </p>

        <div className={styles.wrapper__email__notify}>
          <div className={styles.wrapper__email__notify__icon}>
            <EmailIcon />
          </div>
          <p className={styles.wrapper__email__notify__text}>
            <Text emptyTag text='EmailVerification' />
            <span className={styles.wrapper__email__notify__resend} role='button' onClick={handleClick}>
              <Text emptyTag text='youCanResendIt' RText='.' />
            </span>
          </p>
        </div>

        <p role='button' className={styles.wrapper__link} onClick={onReturnToLogin}>
          <Text emptyTag text='ReturnToSignIn' />
        </p>
      </div>
    </Modal>
  )
}

export default CheckEmailModal
