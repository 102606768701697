import { isEmpty } from 'lodash'
import type { FieldErrors } from 'react-hook-form'

import type { TValidationCondition, TDefaultDate, TRegistrationFormData } from './types'

export const fakeDataSteps = new Array(3).fill('')

export const passwordValidationConditions: TValidationCondition[] = [
  {
    id: 1,
    text: 'ValidCharacters',
    regex: /.{6,20}/,
  },
  {
    id: 2,
    text: 'ValidSpecialSymbol',
    regex: /[!@#$%^&*().\-_=+{};:,<.>]/,
  },
  {
    id: 3,
    text: 'ValidUpperCaseLetter',
    regex: /[A-Z]/,
  },
  {
    id: 4,
    text: 'ValidLowerCaseLetter',
    regex: /[a-z]/,
  },
  {
    id: 5,
    text: 'ValidNumber',
    regex: /[0-9]/,
  },
]

export const defaultDate: TDefaultDate = { day: null, month: null, year: null }

const keysToRemove = ['password', 'passwordConfirm']
export const detectValidationHandler = (
  obj: TRegistrationFormData,
  step: number,
  errors: FieldErrors<any>,
  birthdayFormat: string
) => {
  const detectPassCase = Object.fromEntries(Object.entries(errors).filter(([key]) => !keysToRemove.includes(key)))

  switch (step) {
    case 0:
      const detectOneStepValid =
        !isEmpty(obj?.email) && !isEmpty(obj?.firstName) && !isEmpty(obj?.lastName) && isEmpty(detectPassCase)

      return !detectOneStepValid

    case 1:
      const detectTwoStepValid =
        !isEmpty(obj?.gender) &&
        !isEmpty(obj?.lastName) &&
        !isEmpty(obj?.nickname) &&
        isEmpty(detectPassCase) &&
        !!birthdayFormat &&
        !birthdayFormat.includes('InvalidDate')

      return !detectTwoStepValid

    case 2:
      const detectTreeStepValid = !isEmpty(obj?.password) && !isEmpty(obj?.passwordConfirm) && isEmpty(detectPassCase)

      return !detectTreeStepValid
    default:
      return false
  }
}
