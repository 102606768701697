import { FC, useRef, useState } from 'react'
import classNames from 'classnames'

import { GloboseIcon } from 'assets'
import { Button, Text } from 'components'
import { EButtonVariants, ESizes } from 'types'
import { THome } from 'components/molecules/types'
import { useLanguageTranslation, useOnClickOutside } from 'hooks'

import { languagesDropdownUtils } from './utils'
import styles from './LangDropdown.module.scss'

const LangDropdown: FC<THome> = ({ className = '' }) => {
  const ref = useRef<HTMLDivElement>(null)

  const [dropActive, setDropActive] = useState<boolean>(false)

  const { onLanguageChangeCallback, isEnglish } = useLanguageTranslation()

  const onOutsideClickCallback = () => {
    setDropActive(false)
  }

  // @TODO Change in future
  const onDropdownClickCallback = () => {
    setDropActive(false)
  }

  const onLanguageClickCallback = (lang: string) => () => {
    setDropActive(false)
    onLanguageChangeCallback(lang)
  }

  useOnClickOutside(ref, onOutsideClickCallback)

  const droppedItemWrapperClassName = classNames(styles.wrapper__dropdown, {
    [styles.wrapper__dropped]: dropActive,
  })

  const langItemClassName = (styleCondition: boolean) => {
    return classNames(styles.wrapper__dropdown__item, {
      [styles.wrapper__active__lang]: styleCondition,
    })
  }

  const renderLanguages = languagesDropdownUtils(isEnglish).map(({ text, lang, styleCondition }, idx) => (
    <div key={idx} onClick={onLanguageClickCallback(lang)} role='button'>
      <Text className={langItemClassName(styleCondition)} text={text} dataTestId={`language-option-${idx}`} />
    </div>
  ))

  return (
    <div className={classNames(styles.wrapper, { [className]: className })} ref={ref} data-testid='language-wrapper'>
      <Button
        size={ESizes.Large}
        LeftIcon={GloboseIcon}
        onClick={onDropdownClickCallback}
        variant={EButtonVariants.Quaternary}
      />
      <div className={droppedItemWrapperClassName} data-testid='language-item-wrapper'>
        {renderLanguages}
      </div>
    </div>
  )
}

export default LangDropdown
