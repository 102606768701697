import { object, string } from 'yup'

const registerCertificateScheme = object().shape({
  email: string()
    .required('emailRequired')
    .email('emailValidate')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, 'emailWithoutWhitespace'),
})

export default registerCertificateScheme
