import { type FC, Fragment } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'

import { Text } from 'components'
import { CloseIcon } from 'assets'
import { useLockBodyScroll } from 'hooks'

import type { IModalProps } from './types'
import styles from './Modal.module.scss'

const Modal: FC<IModalProps> = ({
  open,
  onClose,
  children,
  title = '',
  className = '',
  closable = true,
  titleClassName = '',
  contentClassName = '',
  containerClassName = '',
  lockOutsideClickHandler,
  titleContainerClassName = '',
}) => {
  useLockBodyScroll(open)

  const outsideClickHandler = () => {
    if (!lockOutsideClickHandler) {
      onClose()
    } else {
      lockOutsideClickHandler()
    }
  }

  return createPortal(
    <Fragment>
      {open && (
        <div className={classNames(styles.wrapper, { [containerClassName]: containerClassName })}>
          <div role='button' onClick={outsideClickHandler} className={styles.wrapper__bg} />

          <div className={classNames(styles.wrapper__content, { [className]: className })}>
            {title && (
              <div
                className={classNames(styles.wrapper__content__title_container, {
                  [titleContainerClassName]: titleContainerClassName,
                })}
              >
                <h4
                  id='modal-modal-title'
                  className={classNames(styles.wrapper__content__title, {
                    [styles.wrapper__content__title__centered]: !closable,
                    [titleClassName]: titleClassName,
                  })}
                >
                  <Text emptyTag text={title} />
                </h4>

                {closable && (
                  <div className={styles.wrapper__content__icon} onClick={onClose}>
                    <CloseIcon />
                  </div>
                )}
              </div>
            )}

            <div className={classNames(styles.wrapper__content__container, { [contentClassName]: contentClassName })}>
              {children}
            </div>
          </div>
        </div>
      )}
    </Fragment>,

    document.body
  )
}

export default Modal
