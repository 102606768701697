import { EProgram } from 'types'

import { BachelorSecondaryIcon, ExpertSecondaryIcon, MasterSecondaryIcon, ProfessorSecondaryIcon } from 'assets'

export const PaymentSecondaryIconMapper = {
  [EProgram.Master]: MasterSecondaryIcon,
  [EProgram.Expert]: ExpertSecondaryIcon,
  [EProgram.Bachelor]: BachelorSecondaryIcon,
  [EProgram.Professor]: ProfessorSecondaryIcon,

  //@TODO: Need to change this object, Blog, News, Free, FreeLesson don't using in this object

  [EProgram.Blog]: ProfessorSecondaryIcon,
  [EProgram.News]: ProfessorSecondaryIcon,
  [EProgram.Free]: ProfessorSecondaryIcon,
  [EProgram.FreeLesson]: ProfessorSecondaryIcon,
}
