import { InstagramIcon, TelegramIcon, YoutubeIcon } from 'assets'

import { ISocialLinks, ESocialsName } from 'types'

export const courseSocialLinks: ISocialLinks[] = [
  {
    name: ESocialsName.instagram,
    navigate: 'https://www.instagram.com/arsen.amatuni/',
    Icon: InstagramIcon,
  },
  {
    name: ESocialsName.telegram,
    navigate: 'https://t.me/arsenamatuni_chat',
    Icon: TelegramIcon,
  },
  {
    name: ESocialsName.youtube,
    navigate: 'https://www.youtube.com/@AMATUNIARSEN',
    Icon: YoutubeIcon,
  },
]
