import { type FC, useRef } from 'react'
import classNames from 'classnames'

import { GlobalSelectors } from 'store/global/selectors'
import { useResponsive, useOnClickOutside } from 'hooks'
import { useAppDispatch, useAppSelector } from 'libraries/redux'
import { setSidebarClosed, setSidebarOpen, toggleSidebar } from 'store/global'

import MobileSidebar from './MobileSidebar'
import type { TSidebarProps } from './types'
import DesktopSidebar from './DesktopSidebar'
import { sidebarMenuItemsUtils, mobileSidebarMenuItemsUtils } from './utils'
import styles from './Sidebar.module.scss'

const Sidebar: FC<TSidebarProps> = ({ modalState }) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(GlobalSelectors.isSidebarOpen)

  const { isLaptop } = useResponsive()

  const sideBarToggler = () => dispatch(toggleSidebar())

  useOnClickOutside(ref, () => {
    if (isOpen) {
      dispatch(setSidebarClosed())
    }
  })

  const handleSidebarOpen = () => {
    dispatch(setSidebarOpen())
  }

  const sidebarWrapperClassName = classNames(styles.wrapper, {
    [styles.wrapper__closed]: !isOpen,
  })

  return (
    <>
      {isLaptop ? (
        <MobileSidebar
          ref={ref}
          isOpen={isOpen}
          modalState={modalState}
          setOpen={handleSidebarOpen}
          sidebarMenuItems={mobileSidebarMenuItemsUtils}
        />
      ) : (
        <DesktopSidebar
          ref={ref}
          open={isOpen}
          modalState={modalState}
          setOpen={sideBarToggler}
          className={sidebarWrapperClassName}
          setActiveWithoutOpening={setSidebarClosed}
          sidebarMenuItems={sidebarMenuItemsUtils}
        />
      )}

      {isOpen && <div className={styles.is_open} />}
    </>
  )
}

export default Sidebar
