import type { RootState } from 'types'

const getCurrentAuth = (state: RootState) => state.auth

const getSignIn = (state: RootState) => state.auth.signIn

const getSignUp = (state: RootState) => state.auth.signUp

const getVerifyEmail = (state: RootState) => state.auth.verifyEmail

const getGoogleSignIn = (state: RootState) => state.auth.signInWithGoogle

const getResetPassword = (state: RootState) => state.auth.resetPassword

const getForgotPassword = (state: RootState) => state.auth.forgotPassword

const postTwoFactorVerify = (state: RootState) => state.auth.twoFactorVerify
const twoFactorAuthToggle = (state: RootState) => state.auth.twoFactorAuthToggle
const twoFactorAuthenticate = (state: RootState) => state.auth.twoFactorAuthenticate

const getResendVerification = (state: RootState) => state.auth.resendVerification

export const AuthSelectors = {
  getSignIn,
  getSignUp,
  getCurrentAuth,
  getVerifyEmail,
  getGoogleSignIn,
  getResetPassword,
  getForgotPassword,
  twoFactorAuthToggle,
  postTwoFactorVerify,
  twoFactorAuthenticate,
  getResendVerification,
}
