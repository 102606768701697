import { date, object, string } from 'yup'

const becomeExpertScheme = object().shape({
  name: string()
    .required('FieldCannotBeEmpty')
    .min(2, 'BecomeExpertNameMinMax')
    .max(30, 'BecomeExpertNameMinMax')
    .matches(/^[a-zA-Zа-яА-Я\s]+$/, 'BecomeExpertNameMatch'),

  surname: string()
    .required('FieldCannotBeEmpty')
    .min(2, 'BecomeExpertSurnameMinMax')
    .max(30, 'BecomeExpertSurnameMinMax')
    .matches(/^[a-zA-Zа-яА-Я\s]+$/, 'BecomeExpertSurnameMatch'),

  phone: string().min(8, 'BecomeExpertPhoneMin').max(25, 'BecomeExpertPhoneMax').required('FieldCannotBeEmpty'),

  email: string()
    .email('BecomeExpertEmailValid')
    .max(72, 'BecomeExpertEmailMax')
    .required('FieldCannotBeEmpty')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, 'BecomeExpertEmailMatch'),

  specialization: string()
    .required('FieldCannotBeEmpty')
    .max(100, 'BecomeExpertSpecializationMinMax')
    .min(2, 'BecomeExpertSpecializationMinMax'),

  degree: string(),

  university: string()
    .min(2, 'BecomeExpertUniversityMinMax')
    .max(50, 'BecomeExpertUniversityMinMax')
    .required('FieldCannotBeEmpty'),

  endYear: date().required('FieldCannotBeEmpty').max(new Date(), 'InvalidDate').min('1965-01-01', 'InvalidDate'),

  courseName: string()
    .min(2, 'BecomeExpertCourseNameMinMax')
    .max(200, 'BecomeExpertCourseNameMinMax')
    .required('FieldCannotBeEmpty'),

  targetAudience: string()
    .max(500, 'BecomeExpertTargetAudienceMinMax')
    .min(2, 'BecomeExpertTargetAudienceMinMax')
    .required('FieldCannotBeEmpty'),

  courseStructure: string()
    .required('FieldCannotBeEmpty')
    .max(2000, 'BecomeExpertCourseStructureMinMax')
    .min(20, 'BecomeExpertCourseStructureMinMax'),

  structureLink: string()
    .required('FieldCannotBeEmpty')
    .max(2000, 'BecomeExpertStructureLinkMax')
    .url('BecomeExpertStructureLinkMatch')
    .transform(originalValue => {
      return originalValue === '' ? undefined : originalValue
    }),

  prerequisites: string().max(300, 'BecomeExpertPrerequisitesMax'),
  feedbackMethods: string()
    .min(10, 'BecomeExpertFeedbackMethodsMinMax')
    .max(1000, 'BecomeExpertFeedbackMethodsMinMax')
    .required('FieldCannotBeEmpty'),

  courseDescription: string()
    .min(20, 'BecomeExpertCourseDescriptionMinMax')
    .max(2000, 'BecomeExpertCourseDescriptionMinMax')
    .required('FieldCannotBeEmpty'),

  achievements: string()
    .required('FieldCannotBeEmpty')
    .min(2, 'BecomeExpertAchievementsMinMax')
    .max(500, 'BecomeExpertAchievementsMinMax'),

  previousExperience: string()
    .required('FieldCannotBeEmpty')
    .min(20, 'BecomeExpertPreviousExperienceMinMax')
    .max(2000, 'BecomeExpertPreviousExperienceMinMax'),

  studentFeedback: string().max(300, 'BecomeExpertStudentFeedbackMax'),

  reasonToJoin: string()
    .required('FieldCannotBeEmpty')
    .min(20, 'BecomeExpertReasonToJoinMinMax')
    .max(2000, 'BecomeExpertReasonToJoinMinMax'),

  personalGoals: string()
    .required('FieldCannotBeEmpty')
    .min(10, 'BecomeExpertPersonalGoalsMinMax')
    .max(500, 'BecomeExpertPersonalGoalsMinMax'),

  strategy: string().min(20, 'BecomeExpertStrategyMinMax').max(2000, 'BecomeExpertStrategyMinMax'),
})

export default becomeExpertScheme
