import { createAsyncThunk } from '@reduxjs/toolkit'

import { EAuth } from 'types'
import axiosInstance from 'libraries/axios'
import { setAuthModalType } from 'store/global'
import { getCookie, setCookie } from 'libraries/cookie'
import { showNotification, ToastVersions } from 'libraries/toastify'
import type { TLoginFormData } from 'components/layout/auth/Login/types'
import type { TRegistrationFormData } from 'components/layout/auth/Register/types'
import type { TResetPasswordFormData } from 'components/layout/auth/ResetPassword/types'
import type { TForgotPasswordFormData } from 'components/layout/auth/ForgotPassword/types'
import { COOKIE_EXPIRATION_TIME, REFRESH_COOKIE_EXPIRATION_TIME, auth, storeActionsErrorHandler } from 'utils'

import { setCurrentAuth } from './index'
import type { TTwoFactorCode } from './types'

export const clearGoogleData = createAsyncThunk('clear/google/sign-in/data', () => {
  return null
})

export const clearSignInData = createAsyncThunk('clear/sign-in/data', () => {
  return null
})

export const signIn = createAsyncThunk('auth/login', async (body: TLoginFormData, { rejectWithValue, dispatch }) => {
  try {
    const rememberMe = getCookie('rememberMe') === '1'

    const { data } = await axiosInstance.post('/auth/login', body)

    if (data.data.user) {
      setCookie('token', data.data.accessToken, rememberMe ? COOKIE_EXPIRATION_TIME : undefined)
      setCookie('current_auth', 'web2', rememberMe ? REFRESH_COOKIE_EXPIRATION_TIME : undefined)
      setCookie('user', JSON.stringify(data.data.user), rememberMe ? REFRESH_COOKIE_EXPIRATION_TIME : undefined)
      setCookie('refreshToken', data.data.refreshToken, rememberMe ? REFRESH_COOKIE_EXPIRATION_TIME : undefined)

      dispatch(setCurrentAuth(auth.WEB2))
      showNotification(ToastVersions.success, data.message)

      return data.data.user
    } else {
      setCookie('token', data.data.accessToken)

      dispatch(setAuthModalType(EAuth.TwoFA))

      return data.data
    }
  } catch (error: any) {
    storeActionsErrorHandler(error)

    return rejectWithValue(error.response.data)
  }
})

export const signInWithGoogle = createAsyncThunk(
  'auth/google-sign-in',
  async (body: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.post(`/auth/google`, body)
      const rememberMe = getCookie('rememberMe') === '1'

      setCookie('token', data.data.accessToken, rememberMe ? COOKIE_EXPIRATION_TIME : undefined)
      setCookie('is_google_auth', '1', rememberMe ? REFRESH_COOKIE_EXPIRATION_TIME : undefined)
      setCookie('current_auth', 'web2', rememberMe ? REFRESH_COOKIE_EXPIRATION_TIME : undefined)

      if (data.data.user) {
        dispatch(setCurrentAuth(auth.WEB2))

        setCookie('refreshToken', data.data.refreshToken, rememberMe ? REFRESH_COOKIE_EXPIRATION_TIME : undefined)
        setCookie('user', JSON.stringify(data.data.user), rememberMe ? REFRESH_COOKIE_EXPIRATION_TIME : undefined)

        showNotification(ToastVersions.success, data.message)

        return data.data
      } else {
        return data.data
      }
    } catch (error: any) {
      showNotification(ToastVersions.error, error.response.data.message)

      return rejectWithValue(error.response.data)
    }
  }
)

export const signUp = createAsyncThunk('auth/register', async (body: TRegistrationFormData, { rejectWithValue }) => {
  try {
    await axiosInstance.post('/auth/sign-up', body)

    return body
  } catch (error: any) {
    storeActionsErrorHandler(error)

    return rejectWithValue(error.response.data)
  }
})

export const twoFactorQRGenerate = createAsyncThunk('2fa/generate-qr', async () => {
  try {
    const { data } = await axiosInstance.post('/auth/2fa/generate-qr')

    return data
  } catch (error: any) {
    return showNotification(ToastVersions.error, error.response.data.message)
  }
})

export const clearTwoFactorAuthToggle = createAsyncThunk('clear/two/factor/auth', () => {
  return null
})

export const twoFactorAuthToggle = createAsyncThunk('2factor-toggle', async (body: TTwoFactorCode) => {
  try {
    const data = await axiosInstance.post('/auth/2fa/toggle-2fa', body)

    showNotification(ToastVersions.success, data?.data?.message)

    return data
  } catch (error: any) {
    return showNotification(ToastVersions.error, error.response.data.message)
  }
})

export const clearTwoFactorAuthenticate = createAsyncThunk('two/factor/auth/clear', () => {
  return null
})

export const twoFactorAuthenticate = createAsyncThunk(
  '2factor-authenticate',
  async (body: TTwoFactorCode, { dispatch }) => {
    try {
      const data = await axiosInstance.post('auth/2fa/authenticate', body)

      const rememberMe = getCookie('rememberMe') === '1'

      setCookie('token', data?.data.data.accessToken, rememberMe ? COOKIE_EXPIRATION_TIME : undefined)
      setCookie('current_auth', 'web2', rememberMe ? REFRESH_COOKIE_EXPIRATION_TIME : undefined)
      setCookie('user', JSON.stringify(data?.data.data.user), rememberMe ? REFRESH_COOKIE_EXPIRATION_TIME : undefined)
      setCookie('refreshToken', data?.data.data.refreshToken, rememberMe ? REFRESH_COOKIE_EXPIRATION_TIME : undefined)

      dispatch(setCurrentAuth(auth.WEB2))

      showNotification(ToastVersions.success, data?.data?.message)

      return data
    } catch (error: any) {
      return showNotification(ToastVersions.error, error.response.data.message)
    }
  }
)

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (body: TForgotPasswordFormData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post('/auth/forgot-password', body)

      showNotification(ToastVersions.success, data.message)

      return body
    } catch (error: any) {
      storeActionsErrorHandler(error)

      return rejectWithValue(error.response.data)
    }
  }
)

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (body: TResetPasswordFormData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch('/auth/reset-password', body)

      showNotification(ToastVersions.success, data.message)

      return data
    } catch (error: any) {
      showNotification(ToastVersions.error, error.response.data.message)

      return rejectWithValue(error.response.data)
    }
  }
)

export const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async (body: { token: string }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`/auth/verify-email`, body)

      const verifyUserData = data?.data?.user
      const verifyAuthToken = data?.data?.accessToken
      const verifyRefreshToken = data?.data?.refreshToken

      setCookie('current_auth', 'web2', REFRESH_COOKIE_EXPIRATION_TIME)

      if (verifyUserData && verifyAuthToken && verifyRefreshToken) {
        setCookie('token', verifyAuthToken, COOKIE_EXPIRATION_TIME)
        setCookie('refreshToken', verifyRefreshToken, REFRESH_COOKIE_EXPIRATION_TIME)
        setCookie('user', JSON.stringify(verifyUserData), REFRESH_COOKIE_EXPIRATION_TIME)

        showNotification(ToastVersions.success, data.message)

        return data
      }
    } catch (error: any) {
      showNotification(ToastVersions.error, error.response.data.message)

      return rejectWithValue(error.response.data)
    }
  }
)

export const resendVerification = createAsyncThunk(
  'auth/resendVerification',
  async (body: { email: string }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`/auth/resend-verification`, body)

      showNotification(ToastVersions.success, data.message)

      return data
    } catch (error: any) {
      showNotification(ToastVersions.error, error.response.data.message)

      return rejectWithValue(error.response.data)
    }
  }
)
