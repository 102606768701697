import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Text } from 'components'
import { ESizes, EButtonVariants } from 'types'
import { ERoutePaths } from 'libraries/router/types'
import { ArrowLeftIcon, ChangeIcon, DashboardIcon, Error404, Error500, Error502 } from 'assets'

import type { IContent, TErrorsProps } from './types'
import styles from './Errors.module.scss'

const Errors: FC<TErrorsProps> = ({ errorCode }) => {
  const navigate = useNavigate()

  const [content, setContent] = useState<IContent>({
    Icon: Error404,
    title: '404',
    text: '',
    subtitle: '',
  })

  useEffect(() => {
    switch (errorCode) {
      case 404:
        setContent({
          Icon: Error404,
          title: '404',
          text: 'Ooops404',
          subtitle: 'Error404',
        })
        break

      case 500:
        setContent({
          Icon: Error500,
          title: '500',
          text: 'Opps500',
          subtitle: 'Server 500 . We apologies and are fixing the problem. Please try again at a later stage',
        })
        break

      case 502:
        setContent({
          Icon: Error502,
          title: '502',
          text: 'Opps502',
          subtitle: 'Error502',
        })
        break

      default:
        return
    }
  }, [errorCode])

  const renderButtons = () => {
    switch (errorCode) {
      case 404:
        return (
          <div className={styles.wrapper__container__buttons}>
            <Button
              size={ESizes.Large}
              LeftIcon={ArrowLeftIcon}
              onClick={handleNavigateToBack}
              variant={EButtonVariants.Secondary}
              className={styles.wrapper__container__buttons__back}
            >
              <Text text='GoBack' />
            </Button>

            <Button
              size={ESizes.Large}
              LeftIcon={DashboardIcon}
              onClick={handleNavigateToHome}
              variant={EButtonVariants.Primary}
              className={styles.wrapper__container__buttons__overview}
            >
              <Text text='gotoOverview' />
            </Button>
          </div>
        )

      case 500:
        return null

      case 502:
        return (
          <Button
            size={ESizes.Large}
            onClick={refreshPage}
            LeftIcon={ChangeIcon}
            variant={EButtonVariants.Primary}
            className={styles.wrapper__container__reload}
          >
            <Text text='Reload' />
          </Button>
        )
    }
  }

  const { Icon, title, text, subtitle } = content

  const refreshPage = () => {
    window.location.reload()
  }

  const handleNavigateToHome = () => navigate(ERoutePaths.Home)

  const handleNavigateToBack = () => navigate(-1)

  return (
    <article className={styles.wrapper}>
      <div className={styles.wrapper__container}>
        <Icon className={styles.wrapper__container__icon} />

        <div className={styles.wrapper__container__content}>
          <h2 className={styles.wrapper__container__title}>{title}</h2>

          <Text text={text} className={styles.wrapper__container__text} />

          <Text text={subtitle} className={styles.wrapper__container__subtitle} />

          {renderButtons()}
        </div>
      </div>
    </article>
  )
}

export default Errors
