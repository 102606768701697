import type { FC } from 'react'

import { Button } from 'components'
import { SupportIcon } from 'assets'
import { useResponsive } from 'hooks'
import { ESizes, EButtonVariants } from 'types'

import type { TSidebarOpenProps } from '../types'
import styles from './SidebarFooter.module.scss'

const SidebarFooter: FC<TSidebarOpenProps> = ({ open }) => {
  const { isLaptop } = useResponsive()

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__inner}>
        {!isLaptop && (
          <a href='https://t.me/Amatikaca' target='_blank'>
            <Button
              RightIcon={SupportIcon}
              size={ESizes.Large}
              variant={EButtonVariants.Quaternary}
              className={styles.wrapper__inner__button}
              ariaLabel='Support'
            >
              {open && 'Support'}
            </Button>
          </a>
        )}
      </div>
    </div>
  )
}

export default SidebarFooter
