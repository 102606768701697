import { type FC } from 'react'
import { NavLink } from 'react-router-dom'

import { useResponsive } from 'hooks'
import { socialLinksData } from 'utils'
import { EmailIcon, SubscribeIcon } from 'assets'
import { EInput, ESizes, EButtonVariants } from 'types'
import { Button, Input, SocialLinks, Text } from 'components'

import styles from './ComingSoonFooter.module.scss'

const ComingSoonFooter: FC = () => {
  const { isTabletL } = useResponsive()

  return (
    <div className={styles.wrapper}>
      {!isTabletL && <SocialLinks className={styles.wrapper__links} links={socialLinksData} />}

      <div className={styles.wrapper__middle}>
        <div className={styles.wrapper__middle__top}>
          <SubscribeIcon />

          <Text text='FollowOrSubscribe' className={styles.wrapper__middle__top__text} />
        </div>

        {isTabletL && <SocialLinks className={styles.wrapper__links} links={socialLinksData} />}

        <form className={styles.wrapper__middle__bottom}>
          <Input
            name='email'
            Icon={EmailIcon}
            placeholder='EnterEmailAddress'
            size={ESizes.Large}
            type={EInput.EmailType}
          />

          <Button
            className={styles.wrapper__middle__bottom__button}
            variant={EButtonVariants.Primary}
            size={ESizes.Large}
            type='submit'
          >
            Subscribe
          </Button>
        </form>
      </div>

      <div className={styles.wrapper__links__footer}>
        <NavLink target='_blank' to='' className={styles.wrapper__links__footer__href}>
          <Text text='AboutUs' />
        </NavLink>

        <span className={styles.wrapper__links__footer__dot}>•</span>

        <NavLink target='_blank' to='' className={styles.wrapper__links__footer__href}>
          <Text text='PrivacyAndPolicy' />
        </NavLink>
      </div>
    </div>
  )
}

export default ComingSoonFooter
