import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { removeCookie } from 'libraries/cookie'
import { EAuth, type TModalState } from 'types'
import { GlobalSelectors } from 'store/global/selectors'
import { useAppDispatch, useAppSelector } from 'libraries/redux'
import { closeLoginModal, closeRegistrationModal, setAuthModalType, setSidebarClosed } from 'store/global'

type ModalType =
  | 'openTwoFactor'
  | 'openCheckEmail'
  | 'openVerifyEmail'
  | 'openSuccessSave'
  | 'openAuthContainer'
  | 'openTwoFactorMessage'
  | 'openBirthdayRequired'
  | 'openErrorEmailVerified'
  | 'openSuccessEmailVerified'
  | 'openTwoFacRecommendation'

type ModalsState = Record<ModalType, boolean>

const initialModalStateValues = {
  openTwoFactor: false,
  openCheckEmail: false,
  openSuccessSave: false,
  openVerifyEmail: false,
  openAuthContainer: false,
  openTwoFactorMessage: false,
  openBirthdayRequired: false,
  openErrorEmailVerified: false,
  openSuccessEmailVerified: false,
  openTwoFacRecommendation: false,
}

const useModalsSystemState = (): TModalState => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const isLoginModalOpen = useAppSelector(GlobalSelectors.isLoginModalOpen)
  const isRegistrationModalOpen = useAppSelector(GlobalSelectors.isRegistrationModalOpen)

  function isInEAuth(value: any): value is EAuth {
    return Object.values(EAuth).includes(value)
  }

  const [modalsState, setModalsState] = useState<ModalsState>(initialModalStateValues)

  const handleOpenModal = useCallback(
    (modalType: ModalType | EAuth) => {
      setModalsState(prevState => ({
        ...prevState,
        [modalType]: true,
      }))

      if (isInEAuth(modalType)) {
        dispatch(setAuthModalType(modalType))

        setModalsState(prevState => ({
          ...prevState,
          ['openAuthContainer']: true,
        }))
      }
    },
    [dispatch]
  )

  const handleCloseModal = useCallback(
    (modalType: ModalType) => {
      setModalsState(prevState => ({
        ...prevState,
        [modalType]: false,
      }))

      dispatch(setSidebarClosed())

      if (isLoginModalOpen) {
        dispatch(closeLoginModal())
      }

      if (isRegistrationModalOpen) {
        dispatch(closeRegistrationModal())
      }
    },
    [dispatch, isLoginModalOpen, isRegistrationModalOpen]
  )

  /** ------------------------------------ Open handlers -------------------------------- **/
  const openAuthContainerModal = useCallback((type: EAuth) => handleOpenModal(type), [handleOpenModal])
  const openLoginModal = useCallback(() => handleOpenModal(EAuth.Login), [handleOpenModal])
  const openRegisterModal = useCallback(() => handleOpenModal(EAuth.Register), [handleOpenModal])
  const openCheckEmailModal = useCallback(() => handleOpenModal('openCheckEmail'), [handleOpenModal])
  const openVerifyEmailModal = useCallback(() => handleOpenModal('openVerifyEmail'), [handleOpenModal])
  const openSuccessSaveModal = useCallback(() => handleOpenModal('openSuccessSave'), [handleOpenModal])
  const openErrorEmailVerifiedModal = useCallback(() => handleOpenModal('openErrorEmailVerified'), [handleOpenModal])
  const openTwoFactorMessageModal = useCallback(() => handleOpenModal('openTwoFactorMessage'), [handleOpenModal])
  const openSuccessEmailVerifiedModal = useCallback(
    () => handleOpenModal('openSuccessEmailVerified'),
    [handleOpenModal]
  )
  const openBirthdayRequiredModal = useCallback(() => {
    removeCookie('isNewUser')
    handleOpenModal('openBirthdayRequired')
  }, [handleOpenModal])
  const openTwoFacRecommendationModal = useCallback(() => {
    handleOpenModal('openTwoFacRecommendation')
  }, [handleOpenModal])
  const openTwoFactorModal = useCallback(() => {
    handleOpenModal('openTwoFactor')
  }, [handleOpenModal])

  /** ------------------------------------ Close handlers ------------------------------- **/
  const closeAuthContainer = useCallback(() => handleCloseModal('openAuthContainer'), [handleCloseModal])
  const closeCheckEmailModal = useCallback(() => handleCloseModal('openCheckEmail'), [handleCloseModal])
  const closeVerifyEmailModal = useCallback(() => handleCloseModal('openVerifyEmail'), [handleCloseModal])
  const closeSuccessSaveModal = useCallback(() => handleCloseModal('openSuccessSave'), [handleCloseModal])
  const closeTwoFactorMessageModal = useCallback(() => handleCloseModal('openTwoFactorMessage'), [handleCloseModal])
  const closeErrorEmailVerifiedModal = useCallback(() => {
    handleCloseModal('openErrorEmailVerified')
    navigate('/')
  }, [handleCloseModal, navigate])
  const closeSuccessEmailVerifiedModal = useCallback(() => {
    handleCloseModal('openSuccessEmailVerified')
    navigate('/')
  }, [handleCloseModal, navigate])
  const closeBirthdayRequiredModal = useCallback(() => {
    handleCloseModal('openBirthdayRequired')
  }, [handleCloseModal])
  const closeTwoFacRecommendationModal = useCallback(() => {
    handleCloseModal('openTwoFacRecommendation')
  }, [handleCloseModal])
  const closeTwoFactorModal = useCallback(() => {
    handleCloseModal('openTwoFactor')
  }, [handleCloseModal])

  return {
    ...modalsState,
    openLoginModal,
    openRegisterModal,
    openCheckEmailModal,
    openVerifyEmailModal,
    openSuccessSaveModal,
    openAuthContainerModal,
    openErrorEmailVerifiedModal,
    openSuccessEmailVerifiedModal,
    openTwoFactorMessageModal,
    openBirthdayRequiredModal,
    openTwoFacRecommendationModal,
    openTwoFactorModal,
    closeTwoFactorMessageModal,
    closeAuthContainer,
    closeCheckEmailModal,
    closeVerifyEmailModal,
    closeSuccessSaveModal,
    closeErrorEmailVerifiedModal,
    closeSuccessEmailVerifiedModal,
    closeBirthdayRequiredModal,
    closeTwoFacRecommendationModal,
    closeTwoFactorModal,
  }
}

export default useModalsSystemState
