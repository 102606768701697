import { useEffect, type FC } from 'react'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import { EAuth, ESizes } from 'types'
import { getCookie } from 'libraries/cookie'
import { setAuthModalType } from 'store/global'
import { AuthSelectors } from 'store/auth/selectors'
import { GlobalSelectors } from 'store/global/selectors'
import { useLanguageTranslation, useResponsive } from 'hooks'
import { useAppDispatch, useAppSelector } from 'libraries/redux'
import { ToastVersions, showNotification } from 'libraries/toastify'
import { AmatuniPrimaryIcon, AmatuniWithLogo, ArrowRightIcon, PrivacyPolicyFile } from 'assets'
import { Link, Text, Modal, Login, Register, TwoFactorModal, ForgotPassword, ResetPassword } from 'components'

import type { IAuthContainerProps, TAuthClassName, TAuthContent } from './types'
import styles from './AuthContainer.module.scss'

const AuthContainer: FC<IAuthContainerProps> = ({
  open,
  email,
  onClose,
  modalState,
  newPasswordToken,
  type = EAuth.Login,
}) => {
  const dispatch = useAppDispatch()
  const { isLaptop } = useResponsive()
  const { isEnglish } = useLanguageTranslation()
  const { loading } = useAppSelector(AuthSelectors.getGoogleSignIn)
  const authModalType = useAppSelector(GlobalSelectors.authModalType)
  const { data } = useAppSelector(AuthSelectors.twoFactorAuthenticate)

  const tokenCookie = getCookie('token')
  const userCookie = getCookie('user') ? JSON.parse(String(getCookie('user')) ?? '') : null

  const twoFacEnabled = isEmpty(userCookie) && !isEmpty(tokenCookie)

  const closeTwoFacHandler = () => {
    if (data?.data) {
      onClose()
    } else if (!data?.data || twoFacEnabled) {
      showNotification(ToastVersions.info, 'AddVerificationCodeOrLogOut')
    }
  }

  const authContent: TAuthContent = {
    [EAuth.Login]: <Login onClose={onClose} />,
    [EAuth.Register]: <Register openVerifyEmailModal={modalState?.openVerifyEmailModal} onClose={onClose} />,
    [EAuth.ForgotPassword]: <ForgotPassword openCheckEmailModal={modalState?.openCheckEmailModal} onClose={onClose} />,
    [EAuth.ResetPassword]: (
      <ResetPassword
        email={email}
        onClose={onClose}
        newPasswordToken={newPasswordToken}
        openSuccessSaveModal={modalState?.openSuccessSaveModal}
      />
    ),
    [EAuth.TwoFA]: <TwoFactorModal closeHandler={closeTwoFacHandler} />,
  }

  const authClassNames: TAuthClassName = {
    [EAuth.TwoFA]: styles.wrapper__right__twofa,
    [EAuth.Login]: styles.wrapper__right__login,
    [EAuth.Register]: styles.wrapper__right__register,
    [EAuth.ResetPassword]: styles.wrapper__right__reset,
    [EAuth.ForgotPassword]: styles.wrapper__right__forgot,
  }

  const renderCurrentContent = authContent[authModalType ? authModalType : type]
  const renderCurrentClassName = authClassNames[authModalType ? authModalType : type]

  useEffect(() => {
    return () => {
      dispatch(setAuthModalType(''))
    }
  }, [dispatch])

  useEffect(() => {
    if (loading) {
      onClose()
    }
  }, [loading, onClose])

  return (
    <Modal
      open={open}
      containerClassName={styles.wrapper__modal}
      onClose={twoFacEnabled ? closeTwoFacHandler : onClose}
      className={classNames(styles.wrapper, renderCurrentClassName)}
      contentClassName={classNames(styles.wrapper__content, renderCurrentClassName)}
    >
      {!isLaptop ? (
        <div className={styles.wrapper__left}>
          <div className={styles.wrapper__left__header}>
            <AmatuniWithLogo />

            {/* @TODO: don't used */}
            {/* <Text className={styles.wrapper__left__header__text} text='ConnectWalletLeftText' /> */}

            <Link
              size={ESizes.Large}
              navigateTo={
                isEnglish
                  ? 'https://amatuni.gitbook.io/amatuni-eng/'
                  : 'https://amatuni.gitbook.io/final-of-academy-russian/'
              }
              RightIcon={ArrowRightIcon}
              outsideNavigate
            >
              LearnMore
            </Link>
          </div>

          <div className={styles.wrapper__left__footer}>
            <p className={styles.wrapper__left__footer__text}>
              <Text text={authModalType === EAuth.Register ? 'WhenRegistering' : 'ByConnectingWallet'} emptyTag />

              <Link size={ESizes.Small} navigateTo='' disabled className={styles.wrapper__left__footer__terms}>
                TermsOfService
              </Link>
              <Text text='and' emptyTag />

              <NavLink target='_blank ' to={PrivacyPolicyFile} className={styles.wrapper__left__footer__link}>
                <Text text='PrivacyPolicy' emptyTag />
              </NavLink>
            </p>
          </div>

          <div className={styles.wrapper__left__logo}>
            <AmatuniPrimaryIcon />
          </div>
        </div>
      ) : null}

      <div className={styles.wrapper__right}>{renderCurrentContent}</div>
    </Modal>
  )
}

export default AuthContainer
