import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'
import { useResponsive } from 'hooks'

import type { TTimeProps } from '../types'
import styles from './Time.module.scss'

const Time: FC<TTimeProps> = ({ date, name, isPreSale = false, className = '' }) => {
  const { isTabletL } = useResponsive()

  return (
    <div className={classNames(styles.wrapper, { [styles.pre_sale]: isPreSale, [className]: className })}>
      <span className={classNames(styles.wrapper__date, { [styles.pre_sale__date]: isPreSale })}>{date}</span>

      <Text text={isTabletL ? name[0] : name} className={styles.wrapper__name} />
    </div>
  )
}

export default Time
