import { FacebookIcon, InstagramIcon, TelegramIcon, YoutubeIcon } from 'assets'

import { ISocialLinks, ESocialsName } from 'types'

export const courseSocialLinks: ISocialLinks[] = [
  {
    name: ESocialsName.facebook,
    navigate: 'https://www.facebook.com/amatunicryptoacademy',
    Icon: FacebookIcon,
  },
  {
    name: ESocialsName.instagram,
    navigate: 'https://www.instagram.com/amatuni_crypto_academy/',
    Icon: InstagramIcon,
  },
  {
    name: ESocialsName.telegram,
    navigate: 'https://t.me/amatuni_crypto_academy',
    Icon: TelegramIcon,
  },
  {
    name: ESocialsName.youtube,
    navigate: 'https://www.youtube.com/channel/UC1F2PB89E2tI84YUXMfwZ4A',
    Icon: YoutubeIcon,
  },
]
