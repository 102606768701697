import type { FC } from 'react'

import { VideoCardSkeleton } from 'components'

import type { TSkeletonProps, TSkeletonUtils } from './types'

const Skeleton: FC<TSkeletonProps> = ({ variant = 'videoCard' }) => {
  const skeletonUtils: TSkeletonUtils = {
    videoCard: <VideoCardSkeleton />,
  }

  const renderSkeleton = skeletonUtils[String(variant)]

  return <>{renderSkeleton}</>
}

export default Skeleton
