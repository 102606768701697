import type { TModalState } from 'types'

export enum EResponseModal {
  Reset = 'auth-reset',
  Verification = 'auth-verification',
}

export type TModalSystemProps = {
  modalState: TModalState
  isUserBirthday: boolean
}
