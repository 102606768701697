import { type FC, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useOnClickOutside } from 'hooks'
import { ArrowDownIcon, CalendarIcon } from 'assets'

import type { TDatePickerMUIprops } from './types'
import DatePickerMUI from './DatePickerStyled'

const DatePicker: FC<TDatePickerMUIprops> = ({
  views,
  label,
  format,
  maxDate,
  minDate,
  value,
  onChange,
  className = '',
  disableFuture,
  placeholder = '',
  isCalendar = false,
}) => {
  const { t } = useTranslation()
  const ref = useRef<any>(null)

  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useOnClickOutside(ref, handleClose)

  const arrowClasses = classNames('arrow-icon', {
    ['rotated']: open,
  })

  return (
    <DatePickerMUI
      views={views}
      label={label}
      value={value}
      format={format}
      open={open}
      maxDate={maxDate}
      minDate={minDate}
      onOpen={handleOpen}
      onChange={onChange}
      closeOnSelect={open}
      onClose={handleClose}
      closeHandler={handleClose}
      disableFuture={disableFuture}
      className={classNames({ [className]: className })}
      slotProps={{ textField: { placeholder: String(t(placeholder)) } }}
      slots={{
        openPickerIcon: () =>
          isCalendar ? <CalendarIcon /> : <ArrowDownIcon ref={ref} className={arrowClasses} onClick={handleClose} />,
      }}
    />
  )
}

export default DatePicker
