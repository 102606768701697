import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'

import type { TDividerWithTextProps } from './types'
import styles from './DividerWithText.module.scss'

const DividerWithText: FC<TDividerWithTextProps> = ({
  text,
  className = '',
  dividerClassName = '',
  textClassName = '',
  NearTextIcon,
}) => (
  <div className={classNames(styles.wrapper, { [className]: className })}>
    <div className={classNames(styles.wrapper__divider, { [dividerClassName]: dividerClassName })} />

    <Text
      text={text}
      LeftIcon={NearTextIcon}
      className={classNames(styles.wrapper__divider__text, { [textClassName]: textClassName })}
    />

    <div className={classNames(styles.wrapper__divider, { [dividerClassName]: dividerClassName })} />
  </div>
)

export default DividerWithText
