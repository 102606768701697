import type { FC } from 'react'

export type TRoutePageType = {
  element: FC
  path: string
  title: string
  isPrivate?: boolean
}

export enum ERoutePaths {
  Home = '/',
  Error = '*',
  Portfolio = '/portfolio',
  Settings = '/settings',
  MyAccount = '/my-account',

  ComingSoon = '/coming-soon/:title',
  ComingSoonNews = '/coming-soon/news',
  ComingSoonLibrary = '/coming-soon/library',
}
