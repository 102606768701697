import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { store } from 'libraries/redux'
import { PageLayout } from 'components'
import { ThemeContextProvider } from 'context'
import ToastServices from 'libraries/toastify/toastServices'

import './styles/index.global.scss'

ReactGA.initialize('G-JRC0NEEBB3')

const App = () => (
  <BrowserRouter>
    <Provider store={store}>
      <ThemeContextProvider>
        <PageLayout />
        <ToastServices />
      </ThemeContextProvider>
    </Provider>
  </BrowserRouter>
)

export default App
