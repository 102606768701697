import { type FC } from 'react'
import { Skeleton } from '@mui/material'

import styles from './VideoCardSkeleton.module.scss'

const VideoCardSkeleton: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper__image__container}>
      <Skeleton className='skeleton__dark' width='100%' height='100%' />
    </div>

    <div className={styles.wrapper__container}>
      <Skeleton className='skeleton__dark' width='100%' height='60px' />
    </div>
  </div>
)

export default VideoCardSkeleton
