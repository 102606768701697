import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'

import type { TTogglerProps } from './types'
import styles from './Toggler.module.scss'

const Toggler: FC<TTogglerProps> = ({ on = false, toggleHandler }) => (
  <div role='button' onClick={toggleHandler} className={styles.container}>
    <Text
      text='OFF'
      tagName='span'
      className={classNames(styles.container__item, {
        [styles.container__item_de_active]: !on,
      })}
    />

    <Text
      text='ON'
      tagName='span'
      className={classNames(styles.container__item, {
        [styles.container__item_active]: on,
      })}
    />
  </div>
)

export default Toggler
