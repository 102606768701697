import { isAfter, isBefore, sub } from 'date-fns'

const multipartDatePickerValue = (year?: string | null, month?: string | null, day?: string | null) => {
  if (month && year && day) {
    const formattedDay = day.length === 1 ? '0' + day : day
    const dateFormatted = `${year}-${month}-${formattedDay}`
    const isDateInValid = new Date(dateFormatted).toString().includes('Invalid Date')
    const isDateAfterCurrent = isAfter(new Date(dateFormatted), sub(new Date(), { years: 18, days: 1 }))
    const isDateVeryPast = isBefore(new Date(dateFormatted), new Date('1950-01-01'))

    if (isDateInValid) {
      return 'InvalidDate'
    }

    if (isDateAfterCurrent) {
      return 'InvalidDateMin'
    }

    if (isDateVeryPast) {
      return 'InvalidDateMax'
    }

    return dateFormatted
  }

  return ''
}

export default multipartDatePickerValue
