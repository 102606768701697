import { useState, type FC, type ChangeEvent, useRef, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import { noop } from 'lodash'

import { Input } from 'components'
import { ArrowDownIcon } from 'assets'
import { useOnClickOutside, useResponsive } from 'hooks'

import type { TDayPicker } from './types'
import styles from './DayPicker.module.scss'

const DayPicker: FC<TDayPicker> = ({
  label,
  month,
  year,
  inputSize,
  onChange,
  name,
  maxDate,
  dateValue,
  setValue,
  placeholder,
  className = '',
}) => {
  const containerRef = useRef<HTMLInputElement>(null)
  const { isLaptop } = useResponsive()

  const [drop, setDrop] = useState<boolean>(false)

  const handleValueChange = (value: string) => {
    setValue(prev => ({ ...prev, day: value }))
    setDrop(false)
  }

  const dropdownDropHandler = () => {
    setDrop(prev => !prev)
  }

  const dropdownCloseHandler = () => {
    setDrop(false)
  }

  useOnClickOutside(containerRef, dropdownCloseHandler)

  const dayCalc =
    dateValue?.month || dateValue?.year ? new Date(Number(dateValue?.year), Number(dateValue?.month), 0).getDate() : 31

  const onInputValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const validValue =
        isNaN(Number(event?.target?.value)) || !event?.target?.value
          ? ''
          : Math.max(1, Math.min(Number(dayCalc), Number(event?.target?.value)))

      setValue(prev => ({ ...prev, day: String(validValue) }))

      if (onChange) {
        onChange(event)
      }
    },
    [dayCalc, onChange, setValue]
  )

  useEffect(() => {
    if (dayCalc < Number(dateValue?.day)) {
      setValue(prev => ({ ...prev, day: String(dayCalc) }))
    }
  }, [month, setValue, dateValue?.day, year, dayCalc])

  const renderOptions = new Array(dayCalc).fill('').map((element, index) => (
    <div
      className={classNames(styles.wrapper__dropdown__item, {
        [styles.wrapper__dropdown__item__disabled]: Number(maxDate) - 1 < index,
        [styles.wrapper__dropdown__item__active]: index + 1 === Number(dateValue),
      })}
      key={element + index + month}
      onClick={!maxDate || maxDate > index ? () => handleValueChange(String(index + 1)) : noop}
      role='button'
    >
      <span>{index + 1}</span>
    </div>
  ))

  return (
    <div ref={containerRef} className={classNames(styles.wrapper, { [className]: className })}>
      <div role='button' onClick={dropdownDropHandler}>
        <Input
          name={name ?? ''}
          maxLength={2}
          value={dateValue?.day ?? ''}
          onChange={(_, event) => onInputValueChange(event)}
          placeholder={placeholder}
          size={inputSize}
        />

        {label && (
          <span className={classNames(styles.wrapper__label, { [styles.wrapper__label__active]: drop })}>{label}</span>
        )}

        {!isLaptop ? (
          <ArrowDownIcon className={classNames(styles.wrapper__arrow, { [styles.wrapper__arrow__flipped]: drop })} />
        ) : null}
      </div>

      <div className={classNames(styles.wrapper__dropdown, { [styles.wrapper__dropdown__dropped]: drop })}>
        {renderOptions}
      </div>
    </div>
  )
}

export default DayPicker
