import { lazy } from 'react'

import { ERoutePaths, type TRoutePageType } from './types'

const Home = lazy(() => import('pages/Home'))
const Error = lazy(() => import('pages/Error'))
const Settings = lazy(() => import('pages/Settings'))
const MyAccount = lazy(() => import('pages/MyAccount'))
const ComingSoon = lazy(() => import('pages/ComingSoon'))
const Portfolio = lazy(() => import('pages/Portfolio'))

const routesList: TRoutePageType[] = [
  {
    element: Home,
    path: ERoutePaths.Home,
    title: 'Amatuni Home',
  },
  {
    element: Portfolio,
    path: ERoutePaths.Portfolio,
    title: 'Portfolio',
  },
  {
    title: 'ComingSoon',
    element: ComingSoon,
    path: ERoutePaths.ComingSoon,
  },

  // @TODO: Private routes
  {
    isPrivate: true,
    element: MyAccount,
    title: 'My account',
    path: ERoutePaths.MyAccount,
  },
  {
    isPrivate: true,
    element: Settings,
    title: 'Settings',
    path: ERoutePaths.Settings,
  },

  {
    element: Error,
    path: ERoutePaths.Error,
    title: 'Error Page',
  },
]

export default routesList
