import type { FC } from 'react'
import classNames from 'classnames'

import { EInput, ESizes } from 'types'
import { Input, Text } from 'components'
import { TickPrimaryIcon } from 'assets'

import type { IStep } from './types'
import { passwordValidationConditions } from './utils'
import styles from './Register.module.scss'

const StepThree: FC<IStep> = ({ register, errors, passwordValue }) => {
  const renderValidations = passwordValidationConditions.map(element => (
    <div key={element.id} className={styles.wrapper__validation_container__row}>
      {element.regex.test(`${passwordValue}`) ? (
        <TickPrimaryIcon />
      ) : (
        <div className={styles.wrapper__validation_container__passive} />
      )}

      <Text
        text={element.text}
        className={classNames(styles.wrapper__validation_container__text, {
          [styles.wrapper__validation_container__text__active]: element.regex.test(`${passwordValue}`),
        })}
      />
    </div>
  ))

  return (
    <>
      <div className={styles.wrapper__steps__row_wrap}>
        <Input
          placeholder='EnterPassword'
          type={EInput.PasswordType}
          size={ESizes.Large}
          name='password'
          label='Password'
          register={register}
          maxLength={20}
          error={errors?.password?.message}
        />

        <Input
          placeholder='ConfirmPassword'
          label='ConfirmPassword'
          type={EInput.PasswordType}
          size={ESizes.Large}
          name='passwordConfirm'
          register={register}
          error={errors?.passwordConfirm?.message}
        />
      </div>

      <div className={styles.wrapper__validation_container}>{renderValidations}</div>
    </>
  )
}

export default StepThree
