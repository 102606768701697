import { date, object, string } from 'yup'

const informationScheme = object().shape({
  firstName: string()
    .required('firstNameRequired')
    .matches(/^[^\s]+$/, 'firstNameWithoutWhitespace')
    .min(2, 'firstNameMinLength')
    .max(30, 'firstNameMaxLength'),
  lastName: string()
    .required('lastNameRequired')
    .matches(/^[^\s]+$/, 'lastNameWithoutWhitespace')
    .min(2, 'lastNameMinLength')
    .max(30, 'lastNameRequired'),
  nickname: string()
    .required('nicknameRequired')
    .matches(/^[^\s]+$/, 'nicknameNameWithoutWhitespace')
    .min(4, 'nicknameMinLength')
    .max(15, 'nicknameMaxLength'),
})

export const googleAuthInformationScheme = object().shape(
  {
    firstName: string()
      .required('firstNameRequired')
      .matches(/^[^\s]+$/, 'firstNameWithoutWhitespace')
      .min(2, 'firstNameMinLength')
      .max(30, 'firstNameMaxLength'),
    lastName: string()
      .required('lastNameRequired')
      .matches(/^[^\s]+$/, 'lastNameWithoutWhitespace')
      .min(2, 'lastNameMinLength')
      .max(30, 'lastNameRequired'),
    nickname: string()
      .nullable()
      .notRequired()
      .transform(originalValue => {
        return originalValue === '' ? null : originalValue
      })
      .when('nickname', {
        is: (value: string) => value?.length,
        then: rule =>
          rule
            .min(4, 'nicknameMinLength')
            .max(15, 'nicknameMaxLength')
            .matches(/^[^\s]+$/, 'nicknameNameWithoutWhitespace'),
      }),
    birthday: date().required('FieldCannotBeEmpty'),
  },
  [['nickname', 'nickname']]
)

export default informationScheme
