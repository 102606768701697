import { useCallback, type FC, useEffect } from 'react'
import { isEmpty, isNil, noop } from 'lodash'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { CloseIcon } from 'assets'
import { emailScheme } from 'utils'
import { useResponsive } from 'hooks'
import { setAuthModalType } from 'store/global'
import { Button, Input, Text } from 'components'
import { forgotPassword } from 'store/auth/actions'
import { AuthSelectors } from 'store/auth/selectors'
import { useAppDispatch, useAppSelector } from 'libraries/redux'
import { EAuth, EButtonVariants, EInput, ESizes } from 'types'

import type { TAuthModalProps } from '../types'
import type { TForgotPasswordFormData } from './types'
import styles from './ForgotPassword.module.scss'

const ForgotPassword: FC<TAuthModalProps> = ({ onClose = noop, openCheckEmailModal = noop }) => {
  const dispatch = useAppDispatch()
  const { isLaptop } = useResponsive()
  const { loading, error } = useAppSelector(AuthSelectors.getForgotPassword)
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    reset,
  } = useForm<TForgotPasswordFormData>({
    mode: 'onChange',
    resolver: yupResolver(emailScheme),
  })

  const onLoginClick = () => {
    dispatch(setAuthModalType(EAuth.Login))
  }

  const handleClose = useCallback(() => {
    onClose()
    reset()
  }, [onClose, reset])

  const onSubmit = useCallback(
    (data: TForgotPasswordFormData) => {
      dispatch(forgotPassword(data))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!loading && isNil(error) && isSubmitted && isEmpty(errors)) {
      handleClose()
      openCheckEmailModal()
    }
  }, [loading, error, isSubmitted, handleClose, errors, openCheckEmailModal])

  return (
    <>
      <div className={styles.wrapper__column}>
        <div className='auth_modal_title'>
          <div className='auth_modal_title__container'>
            <Text text='ForgotYourPassword' tagName='h4' className='auth_modal_title__container__text' />

            <CloseIcon onClick={onClose} />
          </div>

          <Text text='RecoverYourPasswordNew' className='auth_modal_title__subtitle' />
        </div>

        <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
          <Input
            name='email'
            register={register}
            size={ESizes.Large}
            type={EInput.EmailType}
            error={errors?.email?.message}
            placeholder='EnterEmailAddress'
            containerClass={styles.wrapper__input}
          />
        </form>
      </div>
      <div className={styles.wrapper__container}>
        <Button
          type='submit'
          size={isLaptop ? ESizes.Medium : ESizes.Large}
          isLoading={loading}
          variant={EButtonVariants.Primary}
          className={styles.wrapper__button}
          onClick={() => handleSubmit(onSubmit)()}
        >
          Recover
        </Button>

        <Text role='button' className={styles.wrapper__link} onClick={onLoginClick} text='ReturnToSignIn' />
      </div>
    </>
  )
}

export default ForgotPassword
