import type { FC } from 'react'
import classNames from 'classnames'

import { EButtonVariants, ESizes } from 'types'
import { Button, Link, Text } from 'components'
import type { THome } from 'components/molecules/types'

import { privateChannelItems } from './utils'
import styles from './GetPrivate.module.scss'

const GetPrivate: FC<THome> = ({ className }) => {
  const renderItems = privateChannelItems.map(({ id, text, Icon }) => (
    <div key={id} className={styles.wrapper__item}>
      <div className={styles.wrapper__item__icon}>
        <Icon />
      </div>

      <Text className={styles.wrapper__item__text} text={text} />
    </div>
  ))

  return (
    <div className={classNames(className, styles.wrapper)}>
      <div className={styles.wrapper__left}>
        {/* @TODO will add soon */}
        {/* <Tooltip className={styles.wrapper__left__tooltip} Icon={InfoIcon} title='lorem ipsum' /> */}
      </div>

      <div className={styles.wrapper__right}>
        <Text text='GetPrivateChannel' tagName='h4' className={styles.wrapper__right__title} />

        <div className={styles.wrapper__right__container}>{renderItems}</div>

        <div className={styles.wrapper__right__links}>
          <Link
            size={ESizes.Large}
            outsideNavigate
            className={styles.wrapper__right__links__button}
            navigateTo='https://amatuni-bot.gitbook.io/amatuni-bot'
          >
            Guide
          </Link>

          <a href='https://t.me/Ars_donat_bot' target='_blank'>
            <Button
              size={ESizes.Medium}
              variant={EButtonVariants.Primary}
              className={styles.wrapper__right__links__button}
            >
              Join
            </Button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default GetPrivate
