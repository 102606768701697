import { type FC, useContext, Context } from 'react'
import classNames from 'classnames'

import { MoonIcon, SunIcon } from 'assets'
import { ThemeContext } from 'context/ThemeContext'
import { TModeSwitchProps } from 'context/ThemeContext/types'

import styles from './ModeSwitch.module.scss'

const ModeSwitch: FC = () => {
  const { isDarkMode } = useContext<TModeSwitchProps>(ThemeContext as Context<TModeSwitchProps>)

  return (
    <div role='button' aria-label='Switch to Dark Mode' className={styles.wrapper}>
      <div
        className={classNames(styles.wrapper__item, {
          [styles.wrapper__light_active]: !isDarkMode,
        })}
      >
        <MoonIcon />
      </div>
      <div
        className={classNames(styles.wrapper__switcher, styles.wrapper__item, {
          [styles.wrapper__switcher_active]: isDarkMode,
        })}
      ></div>
      <div
        className={classNames(styles.wrapper__item, {
          [styles.wrapper__light_active]: isDarkMode,
        })}
      >
        <SunIcon />
      </div>
    </div>
  )
}

export default ModeSwitch
