import { type FC, useState, useCallback, useEffect, Fragment } from 'react'
import { isEmpty, noop } from 'lodash'

import {
  twoFactorAuthToggle,
  twoFactorAuthenticate,
  clearTwoFactorAuthToggle,
  clearTwoFactorAuthenticate,
} from 'store/auth/actions'
import { CloseIcon } from 'assets'
import { useAuthentication } from 'hooks'
import { getCookie } from 'libraries/cookie'
import { setAuthModalType } from 'store/global'
import { DividerWithText, Text } from 'components'
import { AuthSelectors } from 'store/auth/selectors'
import { UserSelectors } from 'store/user/selectors'
import { useAppDispatch, useAppSelector } from 'libraries/redux'

import QrPart from './QrPart'
import TwoFactorFooter from './TwoFactorFooter'
import VerificationCode from './VerificationCode'
import type { TTwoFactorModalProps } from './types'
import styles from './TwoFactorModal.module.scss'

const TwoFactorModal: FC<TTwoFactorModalProps> = ({ showQrPart = false, closeHandler = noop, modalState }) => {
  const dispatch = useAppDispatch()
  const { handleLogout } = useAuthentication()

  const cookieToken = getCookie('token')
  const userCookie = getCookie('user') ? JSON.parse(String(getCookie('user')) ?? '') : null

  const twoFacEnabled = isEmpty(userCookie) && !isEmpty(cookieToken)

  const { data } = useAppSelector(UserSelectors.getProfile)
  const { data: signInData } = useAppSelector(AuthSelectors.getSignIn)
  const { data: googleData } = useAppSelector(AuthSelectors.getGoogleSignIn)
  const twoFactorAuthenticateData = useAppSelector(AuthSelectors.twoFactorAuthenticate)
  const { data: twoFToggle, loading } = useAppSelector(AuthSelectors.twoFactorAuthToggle)

  const isVerifyUser = data?.isTwoFactorEnabled

  const [verifyValue, setVerifyValue] = useState<string>('')
  const verifyCode = { code: verifyValue }

  const isVerifyNumAllow = verifyValue?.length === 6

  const verifyHandler = () => {
    if (isVerifyNumAllow) {
      if (twoFacEnabled && (googleData?.isTwoFactorEnabled || signInData?.token || isEmpty(signInData?.user))) {
        dispatch(twoFactorAuthenticate(verifyCode))
      } else {
        dispatch(twoFactorAuthToggle(verifyCode))
      }
    }
  }

  const closeLocalHandler = useCallback(() => {
    if (!twoFacEnabled) {
      setVerifyValue('')
      dispatch(setAuthModalType(''))
      dispatch(clearTwoFactorAuthenticate())
      closeHandler()
    } else {
      dispatch(setAuthModalType(''))
      dispatch(clearTwoFactorAuthenticate())
      modalState?.closeTwoFactorModal()
      handleLogout()
    }
  }, [closeHandler, dispatch, handleLogout, modalState, twoFacEnabled])

  const twoFacAuthenticate = !isEmpty(twoFactorAuthenticateData?.data) && !twoFactorAuthenticateData?.loading

  const twoFacToggle = twoFToggle?.data?.statusCode === 200 && !loading

  useEffect(() => {
    if (showQrPart && twoFacToggle) {
      closeLocalHandler()
    }
  }, [closeLocalHandler, dispatch, showQrPart, twoFacToggle])

  useEffect(() => {
    if (!showQrPart && twoFacAuthenticate) {
      closeLocalHandler()
    }
  }, [closeLocalHandler, dispatch, showQrPart, twoFacAuthenticate])

  useEffect(() => {
    dispatch(clearTwoFactorAuthToggle())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.modal_content__wrapper}>
      <div className={styles.modal_content__scrollable}>
        <div className='auth_modal_title'>
          <div className='auth_modal_title__container'>
            <Text text='TwoFactorTitle' className='auth_modal_title__container__text' />

            {!twoFacEnabled && <CloseIcon onClick={closeLocalHandler} />}
          </div>

          <Text text='ActivateTwoFactorDescription' RText='.' className='auth_modal_title__subtitle' />
        </div>

        <div className={styles.modal_content__container}>
          {!isVerifyUser && showQrPart && (
            <Fragment>
              <QrPart />

              <DividerWithText text='OR' />
            </Fragment>
          )}

          <VerificationCode
            verifyValue={verifyValue}
            setVerifyValue={setVerifyValue}
            isVerifyNumAllow={isVerifyNumAllow}
          />
        </div>
      </div>

      <TwoFactorFooter
        disabled={!isVerifyNumAllow}
        verifyHandler={verifyHandler}
        cancelHandler={closeLocalHandler}
        required2FacField={twoFacEnabled}
        isLoading={loading || twoFactorAuthenticateData?.loading}
      />
    </div>
  )
}

export default TwoFactorModal
