import { Fragment, type FC } from 'react'
import classNames from 'classnames'

import { formatNumber } from 'utils'
import { useResponsive } from 'hooks'
import { useAppSelector } from 'libraries/redux'
import { ContentSelectors } from 'store/content/selectors'
import type { THome } from 'components/molecules/types'

import StatItem from './StatItem'
import styles from './AuthorStatistics.module.scss'

const AuthorStatistics: FC<THome> = ({ className }) => {
  const { isLaptop } = useResponsive()
  const { data } = useAppSelector(ContentSelectors.getSubscribersCount)

  // @TODO change it after we will have the data
  const instaFollowersCount = 17000

  const authorStatisticsData = [
    {
      id: 1,
      count: Number(data?.total) + instaFollowersCount,
      text: 'AllSubscribers',
    },
    {
      id: 2,
      count: data?.youtubeSubscribers,
      text: 'Youtube ',
    },
  ]

  const secondPart = [
    {
      id: 4,
      count: data?.tgSubscribers,
      text: 'Telegram',
    },
    {
      id: 3,
      count: data?.videos,
      text: 'Video',
    },
  ]

  const renderData = authorStatisticsData.map(({ id, count, text }, index) => (
    <Fragment key={id}>
      <StatItem count={formatNumber(Number(count))} text={text} />

      {authorStatisticsData.length - 1 !== index && <div className={styles.wrapper__line} />}
    </Fragment>
  ))

  const renderSecondPartData = secondPart.map(({ id, count, text }, index) => (
    <Fragment key={id}>
      <StatItem count={formatNumber(Number(count))} text={text} />

      {authorStatisticsData.length - 1 !== index && <div className={styles.wrapper__line} />}
    </Fragment>
  ))

  return (
    <div className={classNames(className, styles.wrapper)}>
      <div className={styles.wrapper__container}>{renderData}</div>
      <div
        className={classNames({
          [styles.wrapper__line]: !isLaptop,
          [styles.wrapper__line__horizontal]: isLaptop,
        })}
      />
      <div className={styles.wrapper__container}>{renderSecondPartData}</div>
    </div>
  )
}

export default AuthorStatistics
