import { createSlice } from '@reduxjs/toolkit'

import type { TGlobal } from './types'

const initialState: TGlobal = {
  authModalType: '',
  currentFaculty: '',
  isSideBarOpen: false,
  isLoginModalOpen: false,
  isRegistrationModalOpen: false,
}

const GlobalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setSidebarOpen: state => ({
      ...state,
      isSideBarOpen: true,
    }),
    setSidebarClosed: state => ({
      ...state,
      isSideBarOpen: false,
    }),
    toggleSidebar: state => ({
      ...state,
      isSideBarOpen: !state.isSideBarOpen,
    }),
    openLoginModal: state => ({
      ...state,
      isLoginModalOpen: true,
    }),
    openRegistrationModal: state => ({
      ...state,
      isRegistrationModalOpen: true,
    }),
    closeLoginModal: state => ({
      ...state,
      isLoginModalOpen: false,
    }),
    closeRegistrationModal: state => ({
      ...state,
      isRegistrationModalOpen: false,
    }),
    setCurrentFaculty: (state, action) => ({
      ...state,
      currentFaculty: action.payload,
    }),
    setAuthModalType: (state, action) => ({
      ...state,
      authModalType: action.payload,
    }),
  },
})

export const {
  toggleSidebar,
  closeLoginModal,
  closeRegistrationModal,
  setSidebarOpen,
  setSidebarClosed,
  setAuthModalType,
} = GlobalSlice.actions

const globalSliceReducer = GlobalSlice.reducer

export default globalSliceReducer
