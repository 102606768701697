import { FC } from 'react'

import { EmailIcon } from 'assets'
import { Modal, Text } from 'components'
import type { TModalBaseProps } from 'types'
import { AuthSelectors } from 'store/auth/selectors'
import { resendVerification } from 'store/auth/actions'
import { useAppDispatch, useAppSelector } from 'libraries/redux'

import styles from './VerifyEmail.module.scss'

const VerifyEmailModal: FC<TModalBaseProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch()
  const { data } = useAppSelector(AuthSelectors.getSignUp)

  const resendVerificationHandler = () => {
    dispatch(resendVerification({ email: String(data?.email) }))
  }

  return (
    <Modal open={open} onClose={onClose} title='VerifyEmail'>
      <div className={styles.wrapper}>
        <p className={styles.wrapper__label}>
          <Text text='SentLink' tagName='span' /> {data?.email} <Text LText=' ' text='SentLinkSecond' tagName='span' />
        </p>

        <div className={styles.wrapper__email__notify}>
          <div className={styles.wrapper__email__notify__icon}>
            <EmailIcon />
          </div>
          <p className={styles.wrapper__email__notify__text}>
            <Text emptyTag text='EmailVerification' />
            <Text
              RText='.'
              tagName='span'
              text='youCanResendIt'
              onClick={resendVerificationHandler}
              className={styles.wrapper__email__notify__text_resend}
            />
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default VerifyEmailModal
