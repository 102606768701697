import type { FC } from 'react'
import { sub } from 'date-fns'
import { Controller } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { FormControl, Radio, RadioGroup } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { ESizes } from 'types'
import { ErrorIcon } from 'assets'
import { DatePicker, DayPicker, Tooltip, Input, Text } from 'components'
import FormControlLabelMui from 'components/atom/Radio/FormControlLabelMui'

import type { ISecondStep } from './types'
import styles from './Register.module.scss'

const StepTwo: FC<ISecondStep> = ({
  errors,
  control,
  register,
  dateValue,
  setDateValue,
  setRadioValue,
  isDateInvalid,
  birthdayFormat,
}) => {
  const subtractedDate = sub(new Date(), { years: 18, days: 1 })
  const maxDays =
    Number(dateValue?.year) === subtractedDate.getFullYear() &&
    Number(dateValue?.month) - 1 === subtractedDate.getMonth()
      ? subtractedDate.getDate()
      : undefined
  const maxMonths = Number(dateValue?.year) === subtractedDate.getFullYear() ? subtractedDate : undefined

  const changeDateValueHandler = (key: string, value: string) => {
    const isMonth = key === 'month' ? 1 + value : value
    const lessThanTen = Number(value) < 10 ? `0${isMonth}` : isMonth

    setDateValue({
      ...dateValue,
      [key]: lessThanTen,
    })
  }

  return (
    <>
      <div className={styles.wrapper__steps__row_wrap}>
        <Input
          label='Nickname'
          name='nickname'
          size={ESizes.Large}
          register={register}
          placeholder='EnterNickname'
          error={errors?.nickname?.message}
        />

        <div className={styles.wrapper__select}>
          <Text text='SelectGender' className={styles.wrapper__select__title} />
          <Controller
            name='gender'
            defaultValue=''
            control={control}
            render={({ field }) => (
              <FormControl>
                <RadioGroup
                  value={field.value}
                  onChange={e => {
                    setRadioValue(e.target.value)
                    field.onChange(e.target.value)
                  }}
                  name='controlled-radio-buttons-group'
                  className={styles.wrapper__select__gender}
                  aria-labelledby='demo-controlled-radio-buttons-group'
                >
                  <FormControlLabelMui value='male' control={<Radio />} label='Male' />

                  <FormControlLabelMui value='female' control={<Radio />} label='Female' />

                  <FormControlLabelMui value='unknown' control={<Radio />} label='PreferNotToSay' />
                </RadioGroup>
              </FormControl>
            )}
          />
        </div>
      </div>

      <div className={styles.wrapper__select}>
        <div className={styles.wrapper__row}>
          <Text text='SelectBirthday' className={styles.wrapper__select__title} />

          {isDateInvalid ? <Tooltip isError isShowFromStart title={isDateInvalid} Icon={ErrorIcon} /> : null}
        </div>

        <div className={styles.wrapper__select__birthday}>
          <Controller
            name='birthday'
            control={control}
            defaultValue={birthdayFormat}
            render={() => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DayPicker
                  placeholder='Day'
                  maxDate={maxDays}
                  dateValue={dateValue}
                  setValue={setDateValue}
                  inputSize={ESizes.Large}
                  year={dateValue?.year ?? ''}
                  month={dateValue?.month ?? ''}
                />

                <DatePicker
                  format='MM'
                  views={['month']}
                  maxDate={maxMonths}
                  placeholder={dateValue?.month || 'Month'}
                  onChange={(e: any) => changeDateValueHandler('month', e?.getMonth() ?? 0)}
                />

                <DatePicker
                  views={['year']}
                  maxDate={subtractedDate}
                  minDate={new Date('1950-01-01')}
                  placeholder={dateValue?.year || 'Year'}
                  onChange={(e: any) => changeDateValueHandler('year', e?.getFullYear() ?? 0)}
                />
              </LocalizationProvider>
            )}
          />
        </div>
      </div>
    </>
  )
}

export default StepTwo
