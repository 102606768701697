import { isArray } from 'lodash'

import { ToastVersions, showNotification } from 'libraries/toastify'

export const formatTime = (seconds: number) => {
  const days = Math.floor(seconds / (3600 * 24))
  const hours = Math.floor((seconds % (3600 * 24)) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  let formattedTime = ''

  if (days > 0) {
    formattedTime += days + 'd '
  }

  if (hours > 0) {
    formattedTime += hours + 'h '
  }

  if (minutes > 0) {
    formattedTime += minutes + 'min'
  }

  return formattedTime.trim()
}

// Example usage: formatTime(100000) // Output: 1d 3h 46min

// ----------------------------------------------------------------------------------------

export const calculateDaysRemaining = (saleExpirationDate: Date, currentDate: Date): number => {
  const utcSaleExpirationDate = Date.UTC(
    saleExpirationDate.getFullYear(),
    saleExpirationDate.getMonth(),
    saleExpirationDate.getDate()
  )
  const utcCurrentDate = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())

  const millisecondsDifference = utcSaleExpirationDate - utcCurrentDate

  const daysDifference = Math.floor(millisecondsDifference / (1000 * 60 * 60 * 24))

  return daysDifference < 0 ? 0 : daysDifference
}

//date example mm/dd/yyy
// Example usage: calculateDaysRemaining(new Date('05.03.2024', new Date('03.28.2024'))) // Output: 36

// ----------------------------------------------------------------------------------------

export const preSalePriceCalc = (currentAmount: number, salePercantage: number) => {
  return currentAmount - (currentAmount * salePercantage) / 100
}

// ----------------------------------------------------------------------------------------

export const getTextById = (array: any[], id: number) => {
  const tab = array.find(element => element.id === id)

  return tab ? tab.text.toLocaleLowerCase() : ''
}

export const getIdByText = (array: any[], text: string): number => {
  const index = array.findIndex(element => element.text.toLowerCase() === text.toLowerCase())

  return index !== -1 ? array[index].id : 0
}

// ----------------------------------------------------------------------------------------

export const storeActionsErrorHandler = (error: any) => {
  if (isArray(error.response.data.message)) {
    const field = Object.keys(error.response.data.message[0])[0]
    const message = error.response.data.message[0][field]

    message.map((element: string) => showNotification(ToastVersions.error, element))
  } else {
    showNotification(ToastVersions.error, error.response.data.message)
  }
}

// ----------------------------------------------------------------------------------------

export const formatNumber = (num: number) => {
  if (num >= 1000 && num < 1000000) {
    return Math.floor(num / 1000) + 'K+'
  } else if (num >= 1000000 && num < 1000000000) {
    return Math.floor(num / 1000000) + 'M+'
  } else if (num >= 1000000000) {
    return Math.floor(num / 1000000000) + 'B+'
  } else {
    return num
  }
}

// Example usage:
// formatNumber(40678) // Output: "40K+"
