import { useCallback, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { auth } from 'utils'
import { setCurrentAuth } from 'store/auth'
import { AuthSelectors } from 'store/auth/selectors'
import { clearProfileData } from 'store/user/actions'
import { useAppDispatch, useAppSelector } from 'libraries/redux'
import { ToastVersions, showNotification } from 'libraries/toastify'
import { clearGoogleData, clearSignInData } from 'store/auth/actions'
import { getCookie, removeCookie, setCookie } from 'libraries/cookie'

// @TODO: Re-render is visible in my-account page, check using console.log

const useAuthentication = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { currentAuth: currentAuthSelector } = useAppSelector(AuthSelectors.getCurrentAuth)

  const token_web3 = getCookie('token_web3')
  const tokenCookie = getCookie('token') || null
  const currentAuthCookie = getCookie('current_auth')

  const userCookie = getCookie('user') ? JSON.parse(String(getCookie('user')) ?? '') : null
  const user_web3Cookie = getCookie('user_web3') ? JSON.parse(String(getCookie('user_web3')) ?? '') : null

  const currentAuth = currentAuthSelector || currentAuthCookie

  const isAuthenticated = Boolean(!!tokenCookie && !isEmpty(userCookie))
  const isAuthenticatedWeb3 = Boolean(!!token_web3 && !isEmpty(user_web3Cookie))

  const isCurrentWeb2 = currentAuth === auth.WEB2
  const isCurrentWeb3 = currentAuth === auth.WEB3

  const isGoogleAuth = getCookie('is_google_auth') || false

  const handleLogout = useCallback(() => {
    showNotification(ToastVersions.info, 'LogOut')

    removeCookie('user')
    removeCookie('token')
    removeCookie('rememberMe')
    removeCookie('refreshToken')

    dispatch(clearGoogleData())
    dispatch(clearSignInData())
    dispatch(clearProfileData())

    if (isGoogleAuth) {
      dispatch(setCurrentAuth(null))
      removeCookie('is_google_auth')
      navigate('/')
    } else {
      dispatch(setCurrentAuth(null))
      navigate('/')
    }
  }, [dispatch, isGoogleAuth, navigate])

  useEffect(() => {
    if (isCurrentWeb2 && !userCookie) {
      setCookie('current_auth', 'web2')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    token: tokenCookie,
    user_web3: user_web3Cookie,
    token_web3,
    userCookie,
    currentAuth,
    handleLogout,
    isGoogleAuth,
    isCurrentWeb2,
    isCurrentWeb3,
    isAuthenticated,
    isAuthenticatedWeb3,
  }
}

export default useAuthentication
