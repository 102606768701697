import { ERoutePaths } from 'libraries/router/types'
import { DashboardIcon, SupportIcon, PortfolioIcon, NewsIcon, BlogIcon, SettingsIcon } from 'assets'

import type { TSectionItem } from './types'

export const sidebarMenuItemsUtils: TSectionItem[] = [
  {
    title: '',
    menuItems: [
      {
        Icon: DashboardIcon,
        text: 'Overview',
        navigateTo: ERoutePaths.Home,
      },
      {
        Icon: PortfolioIcon,
        text: 'Portfolio',
        navigateTo: ERoutePaths.Portfolio,
      },
      {
        Icon: NewsIcon,
        text: 'News',
        navigateTo: '',
        soon: true,
      },

      {
        Icon: BlogIcon,
        text: 'Blog',
        navigateTo: '',
        soon: true,
      },

      {
        text: 'Setting',
        isPrivate: true,
        Icon: SettingsIcon,
        navigateTo: ERoutePaths.Settings,
      },
    ],
  },
]

export const mobileSidebarMenuItemsUtils: TSectionItem[] = [
  {
    title: 'Other',
    menuItems: [
      {
        Icon: SupportIcon,
        text: 'Support',
        navigateTo: 'https://t.me/Amatikaca',
        soon: false,
      },

      {
        isPrivate: true,
        text: 'Setting',
        Icon: SettingsIcon,
        navigateTo: ERoutePaths.Settings,
      },
    ],
  },
]
