import { object, string } from 'yup'

const loginScheme = object().shape({
  email: string()
    .required('emailRequired')
    .email('emailValidate')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, 'emailWithoutWhitespace'),
  password: string()
    .required('passwordRequired')
    .min(6, 'passwordMinLength')
    .max(20, 'passwordMaxLength')
    .matches(
      /^(?=(?:.*[A-Z]){1,})(?=(?:.*[a-z]){1,})(?=(?:.*\d){1,})(?=(?:.*[!@#$%^&*().\-_=+{};:,<.>]){1,})([A-Za-z0-9!@#$%^&*()\-_=+{};:,<.>]{6,20})/,
      'passwordValidate'
    ),
})

export default loginScheme
