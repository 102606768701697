import styled from '@emotion/styled'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import type { TDatePickerMUIprops } from './types'

const DatePickerMUI = styled(DatePicker)<TDatePickerMUIprops>(() => ({
  width: '100%',
  borderRadius: '8px',
  background: '#142837',
  margin: '0 !important',

  '.MuiInputBase-input': {
    color: '#e6f4ff',
    padding: '12.5px',
  },

  '.MuiFormLabel-root': {
    color: '#e6f4ff',
    fontSize: '16px',
    fontWeight: '300',
    fontFamily: 'Rubik',
    lineHeight: '24px',
  },

  '.MuiInputBase-root': {
    paddingRight: 0,
    borderRadius: '8px',

    '&> fieldset': {
      transition: '0.6s all',
    },

    '&:hover fieldset': {
      borderColor: '#6f7d8c',
    },
  },

  '.MuiButtonBase-root': {
    padding: 0,
    border: '1px',
    color: '#0095fa',
    marginRight: '8px',
  },

  '.MuiFormControlLabel-label': {
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px',
  },

  '.arrow-icon': {
    transition: ' transform 0.7s ease',
  },

  '.arrow-icon.rotated ': {
    transform: 'rotate(180deg)',
  },
}))

export default DatePickerMUI
