import type { IMobileNavBar } from 'types'
import { ERoutePaths } from 'libraries/router/types'
import { BlogIcon, DashboardIcon, NewsIcon, PortfolioIcon } from 'assets'

export const mobileNavBarMenuItem: IMobileNavBar[] = [
  {
    Icon: DashboardIcon,
    navigateTo: ERoutePaths.Home,
  },
  {
    Icon: PortfolioIcon,
    navigateTo: '',
    soon: true,
  },
  {
    Icon: BlogIcon,
    navigateTo: '',
    soon: true,
  },
  {
    Icon: NewsIcon,
    navigateTo: '',
    soon: true,
  },
]
