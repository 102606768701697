import type { FC } from 'react'
import { noop } from 'lodash'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import { EAuth, ESizes } from 'types'
import { useAppSelector } from 'libraries/redux'
import { GlobalSelectors } from 'store/global/selectors'
import { useLanguageTranslation, useResponsive } from 'hooks'
import { Modal, TwoFactorModal, Link, Text } from 'components'
import { AmatuniWithLogo, ArrowRightIcon, PrivacyPolicyFile, AmatuniPrimaryIcon } from 'assets'

import type { TSettingsTwoFactorModalProps } from './types'
import styles from './SettingsTwoFactorModal.module.scss'

const SettingsTwoFactorModal: FC<TSettingsTwoFactorModalProps> = ({ open, showQrPart, modalState, onClose = noop }) => {
  const { isLaptop } = useResponsive()
  const { isEnglish } = useLanguageTranslation()
  const authModalType = useAppSelector(GlobalSelectors.authModalType)

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classNames(styles.wrapper)}
      containerClassName={styles.wrapper__modal}
      contentClassName={classNames(styles.wrapper__content)}
    >
      {!isLaptop ? (
        <div className={styles.wrapper__left}>
          <div className={styles.wrapper__left__header}>
            <AmatuniWithLogo />

            {/* @TODO: don't used */}
            {/* <Text className={styles.wrapper__left__header__text} text='ConnectWalletLeftText' /> */}

            <Link
              size={ESizes.Large}
              navigateTo={
                isEnglish
                  ? 'https://amatuni.gitbook.io/amatuni-eng/'
                  : 'https://amatuni.gitbook.io/final-of-academy-russian/'
              }
              RightIcon={ArrowRightIcon}
              outsideNavigate
            >
              LearnMore
            </Link>
          </div>

          <div className={styles.wrapper__left__footer}>
            <p className={styles.wrapper__left__footer__text}>
              <Text text={authModalType === EAuth.Register ? 'WhenRegistering' : 'ByConnectingWallet'} emptyTag />

              <Link size={ESizes.Small} navigateTo='' disabled className={styles.wrapper__left__footer__terms}>
                TermsOfService
              </Link>
              <Text text='and' emptyTag />

              <NavLink target='_blank ' to={PrivacyPolicyFile} className={styles.wrapper__left__footer__link}>
                <Text text='PrivacyPolicy' emptyTag />
              </NavLink>
            </p>
          </div>

          <div className={styles.wrapper__left__logo}>
            <AmatuniPrimaryIcon />
          </div>
        </div>
      ) : null}

      <TwoFactorModal closeHandler={onClose} showQrPart={showQrPart} modalState={modalState} />
    </Modal>
  )
}

export default SettingsTwoFactorModal
