import { useLocation } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'

import { getCookie, setCookie } from 'libraries/cookie'
import { EModeVariant } from 'types'
import { ERoutePaths } from 'libraries/router/types'

const useDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(true)

  //@TODO after finish the light mode for all pages we will delete this location and all what connected to that
  const location = useLocation()

  useEffect(() => {
    const activeMode = getCookie('theme') as EModeVariant
    const isDarkMode = activeMode === EModeVariant.Dark

    if (location.pathname === ERoutePaths.Home && activeMode) {
      setIsDarkMode(isDarkMode)
      document.documentElement.setAttribute('theme', activeMode)
      setCookie('theme', activeMode)
    } else {
      setIsDarkMode(false)
      document.documentElement.setAttribute('theme', EModeVariant.Light)
      setCookie('theme', EModeVariant.Light)
    }
  }, [location.pathname])

  const updateActiveMode = useCallback(() => {
    const activeMode = !isDarkMode ? EModeVariant.Dark : EModeVariant.Light

    if (location.pathname === ERoutePaths.Home) {
      setCookie('theme', activeMode)
      document.documentElement.setAttribute('theme', activeMode)
      setIsDarkMode(!isDarkMode)
    } else {
      setCookie('theme', EModeVariant.Light)
      document.documentElement.setAttribute('theme', EModeVariant.Light)
      setIsDarkMode(false)
    }
  }, [isDarkMode, location.pathname])

  return { isDarkMode, updateActiveMode }
}

export default useDarkMode
