import { intervalToDuration } from 'date-fns'

export const timeLeft = (date: number | string | Date) => {
  const currentDate = new Date()
  const targetDay = new Date()
  targetDay.setDate(currentDate.getDate() + +date)
  targetDay.setHours(0, 0, 0, 0)
  const difference = intervalToDuration({
    start: currentDate,
    end: targetDay,
  })

  const days = String(difference.days)
  const hours = String(difference.hours).padStart(2, '0')
  const minutes = String(difference.minutes).padStart(2, '0')
  const seconds = String(difference.seconds).padStart(2, '0')

  return [
    { time: days, name: 'Days' },
    { time: hours, name: 'Hours' },
    { time: minutes, name: 'Minutes' },
    { time: seconds, name: 'Seconds' },
  ]
}
