// @TODO:-_- Quantity correspond with BC
export const COOKIE_EXPIRATION_TIME = 0.1667 // 4hour
export const REFRESH_COOKIE_EXPIRATION_TIME = 15 // 15 day

// intercom chat
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_ID || ''
export const INTERCOM_BASE_API = process.env.REACT_APP_INTERCOM_API || ''

//Title
export const PROJECT_TITLE = 'Amatuni'
