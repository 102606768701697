import { FacebookIcon, InstagramIcon, TelegramIcon, TikTokIcon, TwitterIcon, VKIcon, YoutubeIcon } from 'assets'

import { ISocialLinks } from 'types'

export const socialLinksData: ISocialLinks[] = [
  {
    navigate: 'https://www.facebook.com/amatunicryptoacademy',
    Icon: FacebookIcon,
  },
  {
    navigate: 'https://www.instagram.com/amatuni_crypto_academy/',
    Icon: InstagramIcon,
  },
  {
    navigate: 'https://t.me/amatuni_crypto_academy',
    Icon: TelegramIcon,
  },
  {
    navigate: 'https://www.tiktok.com/@amatik.amt',
    Icon: TikTokIcon,
  },

  {
    navigate: 'https://twitter.com/AmatuniAcademy',
    Icon: TwitterIcon,
  },
  {
    navigate: 'https://vk.com/cryptoacademyamatuni',
    Icon: VKIcon,
  },
  {
    navigate: 'https://www.youtube.com/channel/UC1F2PB89E2tI84YUXMfwZ4A',
    Icon: YoutubeIcon,
  },
]
