import type { FC } from 'react'
import classNames from 'classnames'
import OtpInput from 'react-otp-input'
import { Link } from 'react-router-dom'

import { Text } from 'components'

// import type { TVerificationCodeProps } from 'pages/Settings/TwoFactor/types'

import styles from './TwoFactorModal.module.scss'

const VerificationCode: FC<any> = ({ verifyValue, setVerifyValue, isVerifyNumAllow }) => (
  <div className={styles.verify}>
    <div className={styles.verify__head}>
      <Text text='EnterVerificationCode' className={styles.verify__head__title} />

      <div className={styles.verify__head_right}>
        <Text text='DoYouNeedAny' className={styles.verify__head_right_text} />

        <Link target='_blank' to='https://amatuni.gitbook.io/style1/' className={styles.verify__head_right_help}>
          <Text text='help' emptyTag />
        </Link>

        <span className={styles.verify__head_right_text}>?</span>
      </div>
    </div>

    <OtpInput
      numInputs={6}
      inputType='number'
      value={verifyValue}
      onChange={setVerifyValue}
      containerStyle={styles.verify__inputs}
      inputStyle={classNames(styles.verify__inputs_item_container, {
        [styles.verify__inputs_item_container_active]: isVerifyNumAllow,
      })}
      renderInput={props => <input {...props} placeholder='*' />}
    />
  </div>
)

export default VerificationCode
