import type { FC } from 'react'

import type { TStarFillProps } from './types'

const StarFill: FC<TStarFillProps> = ({ translate }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 16 16' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      fill='#3F5166'
      d='M8.92021 1.47921L10.4431 4.5215C10.5923 4.82017 10.8804 5.02727 11.2145 5.07519L14.6211 5.56555C15.4628 5.68707 15.7977 6.70716 15.1886 7.29166L12.7252 9.65874C12.4831 9.89152 12.3729 10.2261 12.4301 10.5547L13.0115 13.8966C13.1547 14.7232 12.2748 15.354 11.5225 14.9629L8.47768 13.3839C8.17918 13.2291 7.82168 13.2291 7.52232 13.3839L4.4775 14.9629C3.72519 15.354 2.84533 14.7232 2.98937 13.8966L3.56987 10.5547C3.62714 10.2261 3.51694 9.89152 3.27485 9.65874L0.8114 7.29166C0.202263 6.70716 0.537201 5.68707 1.37889 5.56555L4.78554 5.07519C5.11961 5.02727 5.40856 4.82017 5.55781 4.5215L7.07979 1.47921C7.45638 0.726982 8.54362 0.726982 8.92021 1.47921Z'
    />
    <g clipPath='url(#clip0_28056_44387)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        fill='#0095FA'
        d='M8.92021 1.47921L10.4431 4.5215C10.5923 4.82017 10.8804 5.02727 11.2145 5.07519L14.6211 5.56555C15.4628 5.68707 15.7977 6.70716 15.1886 7.29166L12.7252 9.65874C12.4831 9.89151 12.3729 10.2261 12.4301 10.5547L13.0115 13.8966C13.1547 14.7232 12.2748 15.3539 11.5225 14.9629L8.47768 13.3839C8.17918 13.229 7.82168 13.229 7.52232 13.3839L4.4775 14.9629C3.72519 15.3539 2.84533 14.7232 2.98937 13.8966L3.56987 10.5547C3.62714 10.2261 3.51694 9.89151 3.27485 9.65874L0.8114 7.29166C0.202263 6.70716 0.537201 5.68707 1.37889 5.56555L4.78554 5.07519C5.11961 5.02727 5.40856 4.82017 5.55781 4.5215L7.07979 1.47921C7.45638 0.726982 8.54362 0.726982 8.92021 1.47921Z'
      />
    </g>

    <defs>
      <clipPath id='clip0_28056_44387'>
        <rect width='20' height='20' fill='white' transform={`translate(${translate})`} />
      </clipPath>
    </defs>
  </svg>
)

export default StarFill
