import { createAsyncThunk } from '@reduxjs/toolkit'

import axiosInstance from 'libraries/axios'
import { REFRESH_COOKIE_EXPIRATION_TIME } from 'utils'
import { getCookie, setCookie } from 'libraries/cookie'
import { ToastVersions, showNotification } from 'libraries/toastify'
import type { TInformationForm } from 'pages/Settings/Information/types'
import type { TChangePasswordForm } from 'pages/Settings/Password/types'

export const clearProfileData = createAsyncThunk('clear/users/profile', () => {
  return null
})

export const getProfileData = createAsyncThunk('user/profile', async () => {
  try {
    const { data } = await axiosInstance.get(`/user/profile`)
    const rememberMe = getCookie('rememberMe') === '1'

    setCookie('user', JSON.stringify(data.data), rememberMe ? REFRESH_COOKIE_EXPIRATION_TIME : undefined)

    setCookie('user', JSON.stringify(data.data), 1)

    return data.data
  } catch (error: any) {
    const errorMessage = error.response.data.message

    showNotification(ToastVersions.error, errorMessage)
  }
})

export const changeUserPassword = createAsyncThunk('users/change-password', async (body: TChangePasswordForm) => {
  try {
    const { data } = await axiosInstance.patch('auth/change-password', body)

    showNotification(ToastVersions.success, data.message)

    return data.data
  } catch (err: any) {
    showNotification(ToastVersions.error, err.response.data.message)
  }
})

export const updateUserInformation = createAsyncThunk('users/update', async (body: TInformationForm) => {
  try {
    const { data } = await axiosInstance.patch('user/update-profile', body)

    showNotification(ToastVersions.success, data.message)

    return data.data
  } catch (error: any) {
    Object.keys(error.response.data).map(key => {
      return showNotification(ToastVersions.error, error.response.data[key])
    })
  }
})
