import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'

import { getCookie } from 'libraries/cookie'
import { getProfileData } from 'store/user/actions'
import { ERoutePaths } from 'libraries/router/types'
import { UserSelectors } from 'store/user/selectors'
import { useAppDispatch, useAppSelector } from 'libraries/redux'

import useLockBodyScroll from './useLockBodyScroll'

const useBirthDayIsRequiredCase = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  const locationIsHome = location.pathname !== ERoutePaths.Home

  const { data } = useAppSelector(UserSelectors.getProfile)
  const userCookie = getCookie('user') ? JSON.parse(String(getCookie('user')) ?? '') : null
  const isUserBirthday =
    (data?.isGoogleAuth && userCookie && isEmpty(userCookie?.birthday)) || (data && !data?.birthday)

  const isScrollLockForGoogle = userCookie?.isGoogleAuth ? Boolean(isUserBirthday) : false
  const lockScrollPosition = isEmpty(userCookie) ? false : isScrollLockForGoogle

  useLockBodyScroll(lockScrollPosition)

  useEffect(() => {
    if (isUserBirthday) {
      if (locationIsHome && userCookie?.isGoogleAuth && !userCookie?.user?.birthday) {
        window.location.replace('/')
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationIsHome, userCookie])

  useEffect(() => {
    if (isUserBirthday && locationIsHome && userCookie?.isGoogleAuth && isEmpty(userCookie?.birthday)) {
      dispatch(getProfileData())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationIsHome, userCookie, dispatch])

  return {
    isUserBirthday,
  }
}

export default useBirthDayIsRequiredCase
