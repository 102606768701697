import type { FC } from 'react'
import { Skeleton } from '@mui/material'

import { Text } from 'components'

import type { TStatItem } from './types'
import styles from './AuthorStatistics.module.scss'

const StatItem: FC<TStatItem> = ({ count, text }) => (
  <div className={styles.wrapper__item}>
    {count ? (
      <p className={styles.wrapper__item__number}>{count}</p>
    ) : (
      <Skeleton className='skeleton__dark' width='100%' />
    )}

    <Text text={text} className={styles.wrapper__item__text} />
  </div>
)

export default StatItem
