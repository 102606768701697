import { Fragment, type FC } from 'react'
import classNames from 'classnames'
import { PieChart, Pie, ResponsiveContainer, PieProps } from 'recharts'

import { Text } from 'components'

import ActiveRadius from './ActiveRadius'
import type { TPieChartPartProps } from './types'
import styles from './PieChart.module.scss'

const PieChartPart: FC<TPieChartPartProps> = ({
  data,
  className,
  activeIndex,
  setMouseActiveIndex,
  innerRadius,
  outerRadius,
}) => {
  const setActiveIndexHandler = (_: PieProps['onMouseEnter'], index: number) => {
    setMouseActiveIndex(index)
  }

  const mouseLeaveHandler = () => {
    setMouseActiveIndex(-1)
  }

  const refactorColors = data?.map((item: any) => ({
    name: item?.coinName,
    fill: item?.color,
    stroke: '#091726',
    strokeWidth: 2,
    percent: item?.percent,
  }))

  return (
    <div className={styles.container}>
      <div className={classNames(className, styles.pie)}>
        <ResponsiveContainer width='100%' height={360}>
          <PieChart className={styles.pie__chart} width={360} height={360}>
            <Pie
              cy='50%'
              cx='50%'
              stroke='fill'
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              isAnimationActive
              dataKey='percent'
              data={refactorColors}
              activeIndex={activeIndex}
              activeShape={ActiveRadius}
              onMouseLeave={mouseLeaveHandler}
              onMouseEnter={setActiveIndexHandler}
            />
          </PieChart>
        </ResponsiveContainer>

        <div className={styles.pie__active}>
          {activeIndex !== -1 && (
            <Fragment>
              {data[activeIndex]?.image && (
                <img
                  alt='coin-image'
                  width={40}
                  height={40}
                  src={data[activeIndex]?.image}
                  className={styles.pie__active__image}
                />
              )}

              <div
                className={styles.pie__active_round_color}
                style={{
                  backgroundColor: data[activeIndex]?.color,
                }}
              />
              <Text className={styles.pie__active_title} text={data[activeIndex]?.coinName} />
              <p className={styles.pie__active_value}>{data[activeIndex]?.percent} %</p>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default PieChartPart
