import styled from '@emotion/styled'
import { Pagination } from '@mui/material'

import type { IPaginationProps } from './types'

const PaginationStyled = styled(Pagination)<IPaginationProps>(() => ({
  width: '100%',

  '.MuiPagination-ul': {
    width: '100%',
    backgroundColor: 'transparent',
  },

  '.Mui-selected': {
    borderRadius: '8px',
    border: '1px solid #0095FA',
    color: '#0095FA !important',
  },

  '.MuiPaginationItem-root': {
    color: '#6f7d8c',
    borderRadius: '8px',
    backgroundColor: 'transparent ',
  },

  '.MuiPaginationItem-root:hover': {
    borderRadius: '8px',
    backgroundColor: '#0E223A',
  },
}))

export default PaginationStyled
