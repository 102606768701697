import type { FC } from 'react'
import classNames from 'classnames'

import type { TPortfolioCoin } from './types'
import styles from './PortfolioCoin.module.scss'

const PortfolioCoin: FC<TPortfolioCoin> = ({
  color,
  image,
  percent,
  coinName,
  className,
  onMouseLeave,
  onMouseEnter,
}) => (
  <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={classNames(className, styles.wrapper)}>
    <div className={styles.wrapper__container}>
      <img src={image} alt='icon_image' width={64} height={64} className={styles.wrapper__image} />

      <div className={styles.wrapper__container__description}>
        <span className={styles.wrapper__color} style={{ backgroundColor: color }} />

        <p className={styles.wrapper__coin_name}>{coinName}</p>
      </div>
    </div>

    <p className={styles.wrapper__percent}>{percent}%</p>
  </div>
)

export default PortfolioCoin
