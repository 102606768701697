import type { FC } from 'react'
import classNames from 'classnames'

import { ComingSoonItem, Text } from 'components'
import { EProgram, EComingSoonItemVariants } from 'types'
import { comingSoonMapper, comingSoonDescriptionMapper } from 'utils'

import type { TNotifiedProps } from './types'
import styles from './Notified.module.scss'

const Notified: FC<TNotifiedProps> = ({
  CustomIcon,
  className,
  isSoonFull = false,
  infoClassName,
  soonClassName,
  title = EProgram.Master,
  description,
}) => {
  const Icon = CustomIcon ? CustomIcon : comingSoonMapper[title as keyof typeof comingSoonMapper]

  const comingSoonDescription = description
    ? description
    : comingSoonDescriptionMapper[title as keyof typeof comingSoonDescriptionMapper]

  const text = title.includes('_') ? title.replace('_', ' ') : title
  const comingSoonSubTitle = title === 'news' ? 'ComingSoonSubtitleNews' : 'ComingSoonSubtitle'

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={classNames(styles.wrapper__notified, infoClassName)}>
        <Text className={styles.wrapper__notified__text} text='ComingSoonTitle' tagName='h3' />

        <div className={styles.wrapper__notified__info}>
          <Icon />

          <Text
            text={text}
            tagName='h4'
            className={classNames(styles.wrapper__notified__info__text, {
              [styles.free_lesson_text]: text.includes('free'),
            })}
          />
        </div>
      </div>

      <Text
        className={classNames(styles.wrapper__notified__text, styles.wrapper__coming)}
        text={comingSoonSubTitle}
        tagName='h4'
      />

      <div className={classNames(styles.wrapper__bottom, soonClassName)}>
        <Text className={styles.wrapper__coming__text} text={comingSoonDescription} />

        <ComingSoonItem
          variant={EComingSoonItemVariants.Soon}
          text={isSoonFull ? 'Soon' : 'ComingSoon'}
          className={styles.wrapper__coming__info}
        />
      </div>
    </div>
  )
}

export default Notified
