import { type FC, Fragment } from 'react'

import { RoutesWrapper } from 'libraries/router'
import { Header, NavBar, Sidebar, ModalSystem } from 'components'
import { useBirthDayIsRequiredCase, useModalsSystemState, useResponsive } from 'hooks'

import styles from './PageLayout.module.scss'

const PageLayout: FC = () => {
  const { isLaptop } = useResponsive()
  const { isUserBirthday } = useBirthDayIsRequiredCase()

  const modalState = useModalsSystemState()

  return (
    <Fragment>
      <main className={styles.wrapper}>
        <Header modalState={modalState} />

        <section className={styles.wrapper__content}>
          <Sidebar modalState={modalState} />

          <RoutesWrapper />
        </section>

        {isLaptop && <NavBar />}
      </main>

      <ModalSystem modalState={modalState} isUserBirthday={isUserBirthday} />
    </Fragment>
  )
}

export default PageLayout
