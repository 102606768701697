import { type FC, useEffect } from 'react'
import { isEmpty, isNil, noop } from 'lodash'
import { GoogleOAuthProvider } from '@react-oauth/google'

import {
  Text,
  AuthContainer,
  CheckEmailModal,
  VerifyEmailModal,
  InformationModal,

  // CookieConsentBanner,
  TwoFactorMessageModal,
  BirthdayRequiredModal,
  SettingsTwoFactorModal,
  TwoFactorRecommendation,
} from 'components'
import { EAuth } from 'types'
import { verifyEmail } from 'store/auth/actions'
import { getProfileData } from 'store/user/actions'
import { useAuthentication, useQuery } from 'hooks'
import { UserSelectors } from 'store/user/selectors'
import { AuthSelectors } from 'store/auth/selectors'
import { useAppDispatch, useAppSelector } from 'libraries/redux'
import { ToastVersions, showNotification } from 'libraries/toastify'
import { getCookie, removeCookie, setCookie } from 'libraries/cookie'

import { EResponseModal, type TModalSystemProps } from './types'

const ModalSystem: FC<TModalSystemProps> = ({ modalState, isUserBirthday }) => {
  const {
    openTwoFactor,
    openCheckEmail,
    openSuccessSave,
    openVerifyEmail,
    openAuthContainer,
    openBirthdayRequired,
    openTwoFactorMessage,
    openErrorEmailVerified,
    openSuccessEmailVerified,
    openTwoFacRecommendation,

    openLoginModal,
    openTwoFactorModal,
    openAuthContainerModal,
    openTwoFactorMessageModal,
    openBirthdayRequiredModal,
    openErrorEmailVerifiedModal,
    openSuccessEmailVerifiedModal,
    openTwoFacRecommendationModal,

    closeAuthContainer,
    closeTwoFactorModal,
    closeCheckEmailModal,
    closeVerifyEmailModal,
    closeSuccessSaveModal,
    closeTwoFactorMessageModal,
    closeBirthdayRequiredModal,
    closeErrorEmailVerifiedModal,
    closeSuccessEmailVerifiedModal,
    closeTwoFacRecommendationModal,
  } = modalState

  const dispatch = useAppDispatch()

  const tokenCookie = getCookie('token')
  const refreshToken = getCookie('refreshToken')
  const isNewUserCookie = Boolean(getCookie('isNewUser'))
  const userCookie = getCookie('user') ? JSON.parse(String(getCookie('user')) ?? '') : null

  const { token, email, modalType } = useQuery()
  const { token: authenticationToken } = useAuthentication()

  const userData = useAppSelector(UserSelectors.getProfile)
  const { data: signInData } = useAppSelector(AuthSelectors.getSignIn)
  const { data: googleData } = useAppSelector(AuthSelectors.getGoogleSignIn)
  const { data: twoFToggle } = useAppSelector(AuthSelectors.twoFactorAuthToggle)
  const { data: isGoogleNewUser } = useAppSelector(AuthSelectors.getGoogleSignIn)
  const { data: verified, error } = useAppSelector(AuthSelectors.getVerifyEmail)

  const required2FacField = isEmpty(userCookie) && !isEmpty(tokenCookie)

  const isEnabled: boolean = twoFToggle?.data?.message?.includes('disabled')
    ? false
    : twoFToggle?.data?.message?.includes('enabled')
  const isNewUser = isNewUserCookie || googleData?.isNewUser

  const closeSecondeLogin = () => {
    if (!required2FacField) {
      removeCookie('isNewUser')
      closeTwoFacRecommendationModal()
      closeTwoFactorModal()
    } else {
      removeCookie('isNewUser')

      showNotification(ToastVersions.info, 'Add2faCodeOrLO')
    }
  }

  const enableTwoFacRecHandler = () => {
    // @TODO: remove closeTwoFacRecommendationModal function after integrate 2fa from BC
    true ? closeTwoFacRecommendationModal() : openTwoFactorModal()
  }

  const emailVerifiedHandler = () => {
    setCookie('isNewUser', '1')
    openTwoFacRecommendationModal()
    closeSuccessEmailVerifiedModal()
  }

  useEffect(() => {
    if (authenticationToken) {
      if (required2FacField || !googleData?.isTwoFactorEnabled || signInData?.token || !isEmpty(signInData?.user)) {
        dispatch(getProfileData())
      }
    }
  }, [dispatch, required2FacField, signInData, authenticationToken, googleData])

  useEffect(() => {
    if (googleData?.isTwoFactorEnabled && Boolean(tokenCookie) && !refreshToken) {
      openTwoFactorModal()
    }
  }, [tokenCookie, googleData, openTwoFactorModal, refreshToken])

  useEffect(() => {
    if (isNewUser && userData?.data?.birthday) {
      closeBirthdayRequiredModal()
      openTwoFacRecommendationModal()
    }
  }, [
    isNewUser,
    googleData,
    isGoogleNewUser,
    userData?.data?.birthday,
    closeBirthdayRequiredModal,
    openTwoFacRecommendationModal,
  ])

  useEffect(() => {
    if (twoFToggle?.data) {
      openTwoFactorMessageModal()
    }
  }, [openTwoFactorMessageModal, isEnabled, twoFToggle?.data])

  useEffect(() => {
    if (modalType === EResponseModal.Verification) {
      dispatch(verifyEmail({ token }))
    }
  }, [dispatch, modalType, token])

  useEffect(() => {
    if (token) {
      if (modalType === EResponseModal.Reset) {
        openAuthContainerModal(EAuth.ResetPassword)
      }

      if (modalType === EResponseModal.Verification) {
        if (verified?.verified && isNil(error)) {
          openSuccessEmailVerifiedModal()
        }

        if (!isNil(error)) {
          openErrorEmailVerifiedModal()
        }
      }
    }
  }, [
    error,
    token,
    verified,
    modalType,
    openAuthContainerModal,
    openErrorEmailVerifiedModal,
    openSuccessEmailVerifiedModal,
  ])

  useEffect(() => {
    if (isUserBirthday) {
      closeAuthContainer()
      openBirthdayRequiredModal()
    }
  }, [isUserBirthday, googleData, openBirthdayRequiredModal, closeAuthContainer])

  return (
    <>
      <GoogleOAuthProvider clientId={String(process.env.REACT_APP_GOOGLE_CLIENT_ID)}>
        <AuthContainer
          email={email}
          modalState={modalState}
          newPasswordToken={token}
          open={openAuthContainer}
          onClose={closeAuthContainer}
        />
      </GoogleOAuthProvider>
      <CheckEmailModal open={openCheckEmail} onClose={closeCheckEmailModal} openLoginModal={openLoginModal} />
      <VerifyEmailModal open={openVerifyEmail} onClose={closeVerifyEmailModal} />
      <InformationModal
        open={openSuccessSave}
        title='ChangedPassword'
        description={
          <>
            <Text text='SuccessfullyReset' />
            <Text emptyTag text='ProceedToLogin' />
          </>
        }
        onClose={closeSuccessSaveModal}
        openLoginModal={openLoginModal}
      />
      <InformationModal
        verifiedCase
        title='EmailVerified'
        openLoginModal={noop}
        onClose={emailVerifiedHandler}
        open={openSuccessEmailVerified}
        description={<Text emptyTag text='AccountVerify' />}
      />
      <BirthdayRequiredModal
        open={openBirthdayRequired}
        closeHandler={closeBirthdayRequiredModal}
        isUserBirthday={isUserBirthday}
      />
      <TwoFactorRecommendation
        onClose={closeSecondeLogin}
        open={openTwoFacRecommendation}
        enableHandler={enableTwoFacRecHandler}
      />
      <SettingsTwoFactorModal
        open={openTwoFactor}
        showQrPart={isNewUser}
        modalState={modalState}
        onClose={closeSecondeLogin}
      />
      <TwoFactorMessageModal isEnabled={isEnabled} open={openTwoFactorMessage} onClose={closeTwoFactorMessageModal} />
      <InformationModal
        type={ToastVersions.error}
        title='FailedVerification'
        open={openErrorEmailVerified}
        openLoginModal={openLoginModal}
        onClose={closeErrorEmailVerifiedModal}
        description={<Text emptyTag text='ExpiredSession' />}
      />
      {/* COOKIE ACCESS BANNER */} {/* @TODO: please check it @T3ysheba,*/}
      {/* {!IS_PRODUCTION && <CookieConsentBanner />} */}
    </>
  )
}

export default ModalSystem
