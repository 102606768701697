import { AmatikCoinIcon, BNBIcon } from 'assets'
import type { TBlinkerContent } from 'components/molecules/types'

export const blinkerContent: TBlinkerContent[] = [
  {
    id: 0,
    text: '123 AMT',
    Icon: AmatikCoinIcon,
  },

  {
    id: 1,
    text: '234 BNB',
    Icon: BNBIcon,
  },
]
