import { useGoogleLogin } from '@react-oauth/google'

import { useAppDispatch } from 'libraries/redux'
import { signInWithGoogle } from 'store/auth/actions'

const useGoogleAuth = () => {
  const dispatch = useAppDispatch()

  const scope = [
    'https://www.googleapis.com/auth/user.birthday.read',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/user.gender.read',
  ].join(' ')
  const onLoginGoogleCallback = useGoogleLogin({
    scope,
    onSuccess: response => dispatch(signInWithGoogle({ accessToken: response.access_token })),
  })

  return onLoginGoogleCallback
}

export default useGoogleAuth
