import { type FC, createContext } from 'react'

import { useDarkMode } from 'hooks'

import type { TModeSwitchProps, TThemContextProps } from './types'

export const ThemeContext = createContext<TModeSwitchProps | null>(null)

const ThemeContextProvider: FC<TThemContextProps> = ({ children }) => {
  const { isDarkMode, updateActiveMode } = useDarkMode()

  return <ThemeContext.Provider value={{ isDarkMode, updateActiveMode }}> {children} </ThemeContext.Provider>
}

export default ThemeContextProvider
