import type { FC } from 'react'
import classNames from 'classnames'

import { QuoteIcon } from 'assets'
import { Text } from 'components'
import type { THome } from 'components/molecules/types'

import { quotes } from './utils'
import styles from './Quotes.module.scss'

// @TODO need to change
const Quotes: FC<THome> = ({ className }) => {
  const renderSliderItems = quotes.map(({ id, text }, index) => (
    <p key={id} style={{ animationDelay: `${index * 3.6}s` }} className={styles.wrapper__slider__text}>
      <Text emptyTag text={text} />
    </p>
  ))

  return (
    <div className={classNames(className, styles.wrapper)}>
      <div className={styles.wrapper__left}>
        <QuoteIcon />
      </div>

      <div className={styles.wrapper__slider}>{renderSliderItems}</div>

      <div className={styles.wrapper__right}>
        <QuoteIcon />
      </div>
    </div>
  )
}

export default Quotes
