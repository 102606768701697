export const base64toFile: (image: string, filename: string, mimeType: string) => File = (
  image: string,
  filename: string,
  mimeType: string
) => {
  const base64Data = atob(image.split(',')[1])

  const arrayBuffer = new Uint8Array(base64Data.length)

  for (let i = 0; i < base64Data.length; i++) {
    arrayBuffer[i] = base64Data.charCodeAt(i)
  }

  const blob = new Blob([arrayBuffer], { type: mimeType })
  const file = new File([blob], filename, { type: mimeType })

  return file
}
