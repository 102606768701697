import { useLocation } from 'react-router-dom'

const useQuery = () => {
  const { search } = useLocation()

  const query = new URLSearchParams(search)

  const modalType = query.get('modal') || ''
  const token = query.get('token') || ''
  const email = query.get('email') || ''
  const courseId = query.get('course') || ''

  return { token, email, modalType, courseId }
}

export default useQuery
