import { createAsyncThunk } from '@reduxjs/toolkit'

import axiosInstance from 'libraries/axios'
import { ToastVersions, showNotification } from 'libraries/toastify'

export const getSubscriberStats = createAsyncThunk('stats', async () => {
  try {
    const { data } = await axiosInstance.get('/content/stats')

    return data.data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)
  }
})

export const getVideos = createAsyncThunk(
  'videos',
  async (
    body: { page?: number; perPage?: number; sortOrder?: string; sortField?: string } | undefined,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axiosInstance.get('/content/videos', {
        params: {
          page: 1,
          perPage: 12,
          ...body,
        },
      })

      return { ...data?.data, page: body?.page || 1 }
    } catch (error: any) {
      showNotification(ToastVersions.error, error.response.data.message)

      return rejectWithValue(error.response.data)
    }
  }
)