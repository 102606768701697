import type { FC } from 'react'
import classNames from 'classnames'

import { NavLink } from 'react-router-dom'
import { ComingSoonItem } from 'components'
import { mobileNavBarMenuItem } from 'utils'
import { EComingSoonItemVariants } from 'types'

import type { THome } from 'components/molecules/types'
import styles from './NavBar.module.scss'

const MobileNavBar: FC<THome> = ({ className }) => {
  const renderNavItems = mobileNavBarMenuItem.map(({ Icon, navigateTo, soon }, idx) => (
    <NavLink
      key={idx}
      to={{
        pathname: navigateTo ?? '',
      }}
      className={({ isActive }) =>
        classNames(styles.wrapper__item, {
          [styles.wrapper__item__active]: isActive && navigateTo,
          [styles.wrapper__item__when_soon]: soon,
        })
      }
    >
      {soon && <ComingSoonItem variant={EComingSoonItemVariants.Soon} className={styles.wrapper__item__soon} />}

      <Icon />
    </NavLink>
  ))

  return <nav className={classNames(styles.wrapper, className)}>{renderNavItems}</nav>
}

export default MobileNavBar
