import type { SVGProps, FC, Dispatch, SetStateAction, ReactNode } from 'react'

import { EAuth } from './components'

export type TSVG = FC<SVGProps<SVGSVGElement>>

export type TViews = 'day' | 'month' | 'year'

export type TSpinnerText = {
  leftText: string
  rightText: string
}

export enum ESocialsName {
  youtube = 'youtube',
  facebook = 'facebook',
  telegram = 'telegram',
  instagram = 'instagram',
}

export interface ISocialLinks {
  name?: ESocialsName
  navigate: string
  Icon: TSVG
}

export interface IWalletButton {
  content: string
  Icon: TSVG
  wallet: string
  active: boolean
  className: string
  id: number
}

export type useStateType<T> = Dispatch<SetStateAction<T>>

export type TSVGProps = {
  Icon: TSVG
}
export type TDate = {
  day?: string | null
  month?: string | null
  year?: string | null
}

export interface IMobileNavBar {
  id?: number
  Icon: TSVG
  navigateTo?: string
  soon?: boolean
  deadline?: number | string
}

export interface IPartners {
  link: string
  Icon: TSVG
}

export type TPreSaleDescription = {
  id?: number
  text: string
}

export interface IGlobalInitialState<DATA, ERROR> {
  loading: boolean
  error: ERROR | null | any
  data: DATA | null
}

export type TTabContent = {
  [key: number]: ReactNode
}

export type TModalBaseProps = {
  open: boolean
  onClose: () => void
}

export type TModalState = {
  openCheckEmail: boolean
  openAuthContainer: boolean
  openVerifyEmail: boolean
  openSuccessSave: boolean
  openSuccessEmailVerified: boolean
  openErrorEmailVerified: boolean
  openTwoFactorMessage: boolean
  openBirthdayRequired: boolean
  openTwoFacRecommendation: boolean
  openTwoFactor: boolean

  openLoginModal: () => void
  openRegisterModal: () => void
  openCheckEmailModal: () => void
  openVerifyEmailModal: () => void
  openSuccessSaveModal: () => void
  openErrorEmailVerifiedModal: () => void
  openSuccessEmailVerifiedModal: () => void
  openAuthContainerModal: (type: EAuth) => void
  openTwoFactorMessageModal: () => void
  openBirthdayRequiredModal: () => void
  openTwoFacRecommendationModal: () => void
  openTwoFactorModal: () => void

  closeTwoFactorMessageModal: () => void
  closeAuthContainer: () => void
  closeCheckEmailModal: () => void
  closeVerifyEmailModal: () => void
  closeSuccessSaveModal: () => void
  closeSuccessEmailVerifiedModal: () => void
  closeErrorEmailVerifiedModal: () => void
  closeBirthdayRequiredModal: () => void
  closeTwoFacRecommendationModal: () => void
  closeTwoFactorModal: () => void
}

export enum ECourseSaleStatus {
  PRESALE = 'presale',
  SALE = 'sale',
}
export enum EPaymentCurrencies {
  USD = 'usd',
}
