import { useEffect, useState } from 'react'

import { handleCopyClick } from 'utils'

const useCopyText = (text: string, onClick?: () => void) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopyClickChange = () => {
    handleCopyClick(text)
    setIsCopied(true)

    if (onClick) {
      onClick()
    }
  }

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false)
      }, 1000)
    }
  }, [isCopied])

  return {
    isCopied,
    handleCopyClickChange,
  }
}

export default useCopyText
