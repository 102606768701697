import { type FC, useState, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { isEmpty, isNil, noop } from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'
import { CSSTransition } from 'react-transition-group'

import { useGoogleAuth, useResponsive } from 'hooks'
import { signUp } from 'store/auth/actions'
import { setAuthModalType } from 'store/global'
import { AuthSelectors } from 'store/auth/selectors'
import { Button, DividerWithText, Text } from 'components'
import { useAppDispatch, useAppSelector } from 'libraries/redux'
import { EAuth, EButtonVariants, ESizes, type TDate } from 'types'
import { multipartDatePickerValue, registrationScheme } from 'utils'
import { ArrowLeftIcon, ArrowRightIcon, CloseIcon, GoogleIcon } from 'assets'

import type { TAuthModalProps } from '../types'

import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import type { TRegistrationFormData } from './types'
import { defaultDate, fakeDataSteps, detectValidationHandler } from './utils'
import styles from './Register.module.scss'

const Register: FC<TAuthModalProps> = ({ onClose = noop, openVerifyEmailModal = noop }) => {
  const [step, setStep] = useState<number>(0)
  const [, setRadioValue] = useState<string>('')
  const [isNext, setNext] = useState<boolean>(false)
  const [toggleValue, setToggleValue] = useState<boolean>(false)
  const [dateValue, setDateValue] = useState<TDate>(defaultDate)

  const {
    reset,
    watch,
    control,
    register,
    clearErrors,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<TRegistrationFormData>({
    mode: 'onChange',
    resolver: yupResolver(registrationScheme) as any,
  })
  const dispatch = useAppDispatch()
  const { isLaptop } = useResponsive()
  const onLoginGoogleCallback = useGoogleAuth()
  const { loading, error } = useAppSelector(AuthSelectors.getSignUp)

  const password = watch(['password'])[0]
  const passwordConfirm = watch(['passwordConfirm'])[0]
  const birthdayFormat = multipartDatePickerValue(dateValue?.year, dateValue?.month, dateValue?.day)
  const isDateInvalid = birthdayFormat.includes('InvalidDate') ? birthdayFormat : null

  const detectValidation = detectValidationHandler(getValues(), step, errors, birthdayFormat)

  const onLoginClick = () => {
    dispatch(setAuthModalType(EAuth.Login))
  }

  const handleClose = useCallback(() => {
    reset()
    onClose()
  }, [onClose, reset])

  const onGoogleButtonClickCallback = () => {
    onLoginGoogleCallback()
    onClose()
  }

  const onComponentEnter = () => {
    setToggleValue(false)
  }

  const onSubmit = useCallback(
    (data: TRegistrationFormData) => {
      const requestPayload: TRegistrationFormData = {
        ...data,
        birthday: birthdayFormat,
      }

      dispatch(signUp(requestPayload))
    },
    [birthdayFormat, dispatch]
  )

  const handleNextStepClick = () => {
    if (step < 2) {
      setStep(prev => prev + 1)
      setNext(true)
      setToggleValue(true)
    }

    if (step === 2) {
      handleSubmit(onSubmit)()
    }
  }

  const handleBackStepClick = () => {
    if (step >= 0) {
      setStep(prev => prev - 1)
      setNext(false)
      setToggleValue(true)
    }
  }

  const steps = [
    { content: <StepOne register={register} errors={errors} />, title: 'SignUp' },
    {
      content: (
        <StepTwo
          errors={errors}
          control={control}
          register={register}
          dateValue={dateValue}
          setDateValue={setDateValue}
          setRadioValue={setRadioValue}
          isDateInvalid={isDateInvalid}
          birthdayFormat={birthdayFormat}
        />
      ),
      title: 'AdditionalSettings',
    },
    { content: <StepThree register={register} errors={errors} passwordValue={password} />, title: 'SetPassword' },
  ]

  const renderSteps = fakeDataSteps.map((_, index) => (
    <div
      key={index}
      className={classNames(styles.wrapper__step, {
        [styles.wrapper__step__passed]: index < step,
        [styles.wrapper__step__current]: index === step,
      })}
    />
  ))

  useEffect(() => {
    if (password === passwordConfirm) {
      clearErrors(['password', 'passwordConfirm'])
    }
  }, [clearErrors, password, passwordConfirm])

  useEffect(() => {
    if (!loading && isNil(error) && isSubmitted && isEmpty(errors)) {
      handleClose()
      openVerifyEmailModal()
    }
  }, [loading, error, isSubmitted, handleClose, openVerifyEmailModal, errors])

  return (
    <>
      <div className={styles.wrapper__scrollable}>
        <div className={styles.wrapper__steps}>
          <div className={classNames('auth_modal_title__container', styles.wrapper__steps__title)}>
            <Text text={steps[step].title} tagName='h4' className='auth_modal_title__container__text' />

            <CloseIcon onClick={onClose} />
          </div>

          <div className={styles.wrapper__step__container}>{renderSteps}</div>

          <p className={styles.wrapper__steps__count}>
            <Text emptyTag text='Step' />
            {` ${step + 1}`} <Text emptyTag text='of' /> 3
          </p>

          {/* @TODO: don't used */}
          {/* <Text className='auth_modal_title__subtitle' text='ConnectWalletLeftText' /> */}
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <CSSTransition
            in={toggleValue}
            timeout={600}
            onEnter={onComponentEnter}
            classNames={`${isNext ? 'step_forward' : 'step_back'}`}
          >
            <div className={styles.wrapper__form}>{steps[step].content}</div>
          </CSSTransition>
        </form>

        {step !== 2 ? (
          <div className={styles.wrapper__have_account}>
            <Text className={styles.wrapper__have_account__text} text='HaveAccount' />

            <Text role='button' onClick={onLoginClick} className='text_link' text='SignIn' />
          </div>
        ) : null}
      </div>

      <div className={styles.wrapper__footer}>
        <div className={styles.wrapper__footer__buttons}>
          {step ? (
            <Button
              size={isLaptop ? ESizes.Medium : ESizes.Large}
              LeftIcon={ArrowLeftIcon}
              variant={EButtonVariants.Tertiary}
              onClick={handleBackStepClick}
            >
              Back
            </Button>
          ) : null}

          <Button
            type='submit'
            size={isLaptop ? ESizes.Medium : ESizes.Large}
            isLoading={loading}
            onClick={handleNextStepClick}
            disabled={!!detectValidation}
            variant={EButtonVariants.Primary}
            RightIcon={step !== 2 ? ArrowRightIcon : null}
          >
            {step === 2 ? 'SignUp' : 'Next'}
          </Button>
        </div>

        <DividerWithText text='OTHER' />

        <Button
          isFillIgnore
          size={isLaptop ? ESizes.Medium : ESizes.Large}
          LeftIcon={GoogleIcon}
          variant={EButtonVariants.Tertiary}
          className={styles.wrapper__google}
          onClick={onGoogleButtonClickCallback}
        >
          ContinueWithGoogle
        </Button>
      </div>
    </>
  )
}

export default Register
