import { forwardRef } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import { Loader, Skeleton } from 'components'

import VideoCard from './VideoCard'
import type { TVideoCards } from './types'
import styles from './VideoMaterials.module.scss'

const VideoCards = forwardRef<HTMLDivElement, TVideoCards>(
  ({ onScroll, data, loading, hasMore, setActivePage }: TVideoCards, ref) => {
    const [sentryRef] = useInfiniteScroll({
      loading,
      hasNextPage: hasMore,
      onLoadMore: () => {
        setActivePage(prev => prev + 1)
      },
      rootMargin: '0px',
    })

    const renderSkeletons = new Array(12).fill('').map((_, index) => <Skeleton key={index} variant='videoCard' />)

    const renderCards = data?.map(element => (
      <VideoCard key={element.videoId} className={styles.wrapper__container__card} videoData={element} />
    ))

    return (
      <div ref={ref} onScroll={onScroll} className={styles.wrapper__container}>
        {loading && Number(data?.length) <= 12 ? renderSkeletons : renderCards}

        {(loading || hasMore) && Number(data?.length) >= 12 && (
          <div className={styles.hidden} ref={sentryRef}>
            <Loader />
          </div>
        )}
      </div>
    )
  }
)

export default VideoCards
