export const quotes = [
  {
    id: 1,
    text: 'Quote1',
  },
  {
    id: 2,
    text: 'Quote2',
  },
  {
    id: 3,
    text: 'Quote3',
  },
]
