import { FloodIcon, T1Icon, T2Icon, T3Icon, T6Icon } from 'assets'

export const privateChannelItems = [
  {
    id: 1,
    text: 'General',
    Icon: T1Icon,
  },
  {
    id: 2,
    text: 'AuthorOpinion',
    Icon: T6Icon,
  },

  {
    id: 3,
    text: 'Trading',
    Icon: T3Icon,
  },
  {
    id: 4,
    text: 'Education',
    Icon: T2Icon,
  },

  {
    id: 5,
    text: 'QuestionsToAuthor',
    Icon: T1Icon,
  },
  {
    id: 6,
    text: 'Flood',
    Icon: FloodIcon,
  },
]
