import { type FC, useCallback } from 'react'

import { Modal, Button } from 'components'
import { ESizes, EButtonVariants } from 'types'
import { useAppSelector } from 'libraries/redux'
import { ToastVersions } from 'libraries/toastify'
import { ErrorIcon, TickPrimaryIcon } from 'assets'
import { UserSelectors } from 'store/user/selectors'

import type { TInformationModal } from './types'
import styles from './information.module.scss'

const InformationModal: FC<TInformationModal> = ({
  open,
  title,
  onClose,
  description,
  openLoginModal,
  verifiedCase = false,
  type = ToastVersions.success,
}) => {
  const { data: userData } = useAppSelector(UserSelectors.getProfile)

  const onReturnToLogin = useCallback(() => {
    onClose()
    openLoginModal()
  }, [onClose, openLoginModal])

  return (
    <Modal
      open={open}
      title={title}
      closable={false}
      onClose={onClose}
      className={styles.wrapper}
      contentClassName={styles.wrapper__content}
      titleContainerClassName={styles.wrapper__title}
    >
      <p className={styles.wrapper__label}>{description}</p>

      {type === ToastVersions.error ? (
        <div className={styles.wrapper__error__icon}>
          <ErrorIcon />
        </div>
      ) : (
        <div className={styles.wrapper__success__icon}>
          <TickPrimaryIcon />
        </div>
      )}

      {!verifiedCase && !userData ? (
        <Button
          size={ESizes.Large}
          onClick={onReturnToLogin}
          className={styles.wrapper__button}
          variant={EButtonVariants.Secondary}
        >
          ReturnToSignIn
        </Button>
      ) : (
        <Button
          onClick={onClose}
          size={ESizes.Large}
          className={styles.wrapper__button}
          variant={EButtonVariants.Secondary}
        >
          OK
        </Button>
      )}
    </Modal>
  )
}

export default InformationModal
