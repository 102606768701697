export enum EProgram {
  Master = 'master',
  Bachelor = 'bachelor',
  Expert = 'expert',
  Professor = 'professor',
  // eslint-disable-next-line lines-around-comment
  // @TODO: Need to change `free` and `free_lesson`
  Free = 'free',
  FreeLesson = 'free_lesson',
  News = 'news',
  Blog = 'blog',
}

export type TProgramVersionProps = {
  programVersion: EProgram
}

export enum EPlacementVariants {
  Right = 'right',
  Left = 'left',
  Top = 'top',
  Bottom = 'bottom',
}

export enum EInput {
  TextType = 'text',
  EmailType = 'email',
  PasswordType = 'password',
}

export enum EProgrammesStatus {
  Finished = 'finished',
  InProgress = 'inProgress',
  NotStarted = 'notStarted',
}

export enum ESizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum EQuestionType {
  Checkbox = 'checkbox',
  Radio = 'radio',
  TextArea = 'textarea',
}

export enum EButtonVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Quaternary = 'quaternary',
}

export type TModalProps = {
  openModal: boolean
  handleClose: () => void
}
export type ETranslatableField = {
  ru: string
  en: string
}

export enum ELanguage {
  en = 'en',
  ru = 'ru',
}

export enum EComingSoonItemVariants {
  Days = 'days',
  Soon = 'soon',
  Presale = 'presale',
  Available = 'available',
}

export enum EAuth {
  TwoFA = '2fa',
  Login = 'login',
  Register = 'register',
  ResetPassword = 'resetPassword',
  ForgotPassword = 'forgotPassword',
}
