import { type FC, useState, useRef } from 'react'
import classNames from 'classnames'

import { CloseIcon } from 'assets'
import { useOnClickOutside } from 'hooks'
import { Text } from 'components'

import { dotsArr } from './utils'
import styles from './SettingsDropdown.module.scss'

const SettingsDropdown: FC = () => {
  const ref = useRef<HTMLDivElement>(null)

  const [isDropped, setDropped] = useState<boolean>(false)

  const handleDropping = () => {
    setDropped(!isDropped)
  }

  const onOutsideClickCallback = () => {
    setDropped(false)
  }

  useOnClickOutside(ref, onOutsideClickCallback)

  const mailRefHandler = () => (window.location.href = 'mailto:info@amatuni.io')

  const renderDots = dotsArr.map(({ id }) => (
    <div
      key={id}
      className={classNames(styles.wrapper__icon__item, styles[`wrapper__icon__dot${id}`], {
        [styles[`wrapper__icon__dot${id}__passive`]]: isDropped,
      })}
    />
  ))

  return (
    <div ref={ref} className={styles.wrapper}>
      <div role='button' aria-label='Open settings' onClick={handleDropping} className={styles.wrapper__icon}>
        {renderDots}

        <div
          className={classNames(styles.wrapper__icon__close, {
            [styles.wrapper__icon__close__active]: isDropped,
          })}
        >
          <CloseIcon />
        </div>
      </div>

      {isDropped && (
        <div className={classNames(styles.wrapper__dropdown, { [styles.wrapper__dropdown__dropped]: isDropped })}>
          <div className={styles.wrapper__dropdown__footer}>
            <p role='button' className={styles.wrapper__dropdown__footer__link} onClick={mailRefHandler}>
              info@amatuni.io
            </p>

            <p className={styles.wrapper__dropdown__footer__version}>V 1.00 </p>

            <Text className={styles.wrapper__dropdown__footer__rights} text='AmatuniRights' />
          </div>
        </div>
      )}
    </div>
  )
}

export default SettingsDropdown
