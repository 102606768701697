import { useCallback, useEffect, useState, type FC } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { isEmpty, isNil, noop } from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  useGoogleAuth,
  useResponsive,
  useAuthentication,

  // useWeb3Utils,
  // useWalletConnect,
  // useWalletConnected,
} from 'hooks'
import { getUser } from 'store/user'
import { signIn } from 'store/auth/actions'
import { CloseIcon, GoogleIcon } from 'assets'
import { setAuthModalType } from 'store/global'
import { AuthSelectors } from 'store/auth/selectors'
import { UserSelectors } from 'store/user/selectors'
import { booleanStateUtil, loginScheme } from 'utils'
import { getCookie, setCookie } from 'libraries/cookie'
import { Button, Checkbox, Input, Text } from 'components'
import { useAppDispatch, useAppSelector } from 'libraries/redux'
import { EAuth, EButtonVariants, EInput, EPlacementVariants, ESizes } from 'types'

import type { TAuthModalProps } from '../types'
import type { TLoginFormData } from './types'
import styles from './Login.module.scss'

const Login: FC<TAuthModalProps> = ({ onClose = noop }) => {
  const [checked, setChecked] = useState<number>(0)

  // const [, setWalletModalOpen] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const { isLaptop } = useResponsive()

  // const { isConnected } = useWalletConnected()
  // const { disconnectWallet, isOpenWeb3modal } = useWalletConnect()
  // const { isWeb3Available } = useWeb3Utils()
  const onLoginGoogleCallback = useGoogleAuth()
  const { isAuthenticatedWeb3 } = useAuthentication()

  const isRememberMe = getCookie('rememberMe') ?? 0
  const isGoogleAuth = getCookie('is_google_auth') ?? 0

  const { data: userData } = useAppSelector(UserSelectors.getProfile)
  const { error, data, loading } = useAppSelector(AuthSelectors.getSignIn)
  const { data: googleData } = useAppSelector(AuthSelectors.getGoogleSignIn)

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted, isDirty },
  } = useForm<TLoginFormData>({
    mode: 'onChange',
    resolver: yupResolver(loginScheme),
  })

  const onCreateAccountButtonClick = useCallback(() => {
    dispatch(setAuthModalType(EAuth.Register))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onForgotPasswordClick = useCallback(() => {
    dispatch(setAuthModalType(EAuth.ForgotPassword))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = useCallback(() => {
    onClose()
    reset()
  }, [onClose, reset])

  const onSubmit = useCallback(
    (data: TLoginFormData) => {
      dispatch(signIn({ email: data.email, password: data.password }))
    },
    [dispatch]
  )

  const handleRememberMe = useCallback(() => {
    setChecked(prev => booleanStateUtil(Number(prev)))
    setCookie('rememberMe', String(booleanStateUtil(checked)))
  }, [checked])

  const onGoogleButtonClickCallback = () => {
    onLoginGoogleCallback()
  }

  useEffect(() => {
    setChecked(Number(isRememberMe))
  }, [isRememberMe])

  // useEffect(() => {
  //   if (!open) {
  //     if (isConnected && !isAuthenticatedWeb3) {
  //       disconnectWallet()
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [open])

  useEffect(() => {
    if (!userData) {
      dispatch(getUser(data))
    } else if (googleData?.token && isGoogleAuth) {
      handleClose()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, dispatch])

  // useEffect(() => {
  //   if (isOpenWeb3modal) {
  //     handleClose()
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isOpenWeb3modal])

  useEffect(() => {
    if (!loading && isNil(error) && isSubmitted && isEmpty(errors)) {
      handleClose()
    }
  }, [loading, error, isSubmitted, handleClose, errors])

  return (
    <>
      <div className={styles.wrapper__column}>
        <div className='auth_modal_title'>
          <div className='auth_modal_title__container'>
            <Text text='LogIntoAccount' tagName='h4' className='auth_modal_title__container__text' />

            <CloseIcon onClick={onClose} />
          </div>
        </div>
      </div>

      <form className={styles.wrapper__form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.wrapper__form__content}>
          <div className={styles.wrapper__column}>
            <Input
              name='email'
              register={register}
              size={ESizes.Large}
              type={EInput.EmailType}
              placeholder='EnterEmailAddress'
              error={errors?.email?.message}
              className={styles.wrapper__input__inp}
              autoComplete={Number(isRememberMe) ? 'on' : 'off'}
            />

            <Input
              name='password'
              register={register}
              size={ESizes.Large}
              type={EInput.PasswordType}
              placeholder='EnterPassword'
              error={errors?.password?.message}
              autoComplete={Number(isRememberMe) ? 'on' : 'off'}
            />
          </div>

          <div className={styles.wrapper__row}>
            <Checkbox
              name='remember'
              label={<Text emptyTag text='RememberMe' />}
              onChange={handleRememberMe}
              labelPlacement={EPlacementVariants.Right}
            />

            <Text
              role='button'
              text='ForgotPassword'
              onClick={onForgotPasswordClick}
              className={styles.wrapper__form__forgot}
            />
          </div>
        </div>

        <div className={styles.wrapper__buttons}>
          <Button
            type='submit'
            disabled={!isValid && isDirty}
            onClick={() => handleSubmit(onSubmit)}
            variant={EButtonVariants.Primary}
            size={isLaptop ? ESizes.Medium : ESizes.Large}
            isLoading={loading}
          >
            SignIn
          </Button>

          <Button
            size={isLaptop ? ESizes.Medium : ESizes.Large}
            variant={EButtonVariants.Tertiary}
            onClick={onCreateAccountButtonClick}
          >
            CreateAnAccount
          </Button>

          <div
            className={classNames(styles.wrapper__column, {
              [styles.wrapper__buttons__auth]: isAuthenticatedWeb3,
            })}
          >
            <Button
              isFillIgnore
              size={isLaptop ? ESizes.Medium : ESizes.Large}
              LeftIcon={GoogleIcon}
              variant={EButtonVariants.Tertiary}
              className={styles.wrapper__buttons__google}
              onClick={onGoogleButtonClickCallback}
            >
              ContinueWithGoogle
            </Button>

            {/* {isWeb3Available && <DividerWithText text='OR' />} */}
          </div>

          {/* {isWeb3Available && !isAuthenticatedWeb3 ? (
            <ConnectButtonHeader
              className={styles.wrapper__buttons__wallet}
              closeLoginModal={handleClose}
              onConnectChange={setWalletModalOpen}
            />
          ) : null} */}
        </div>
      </form>
    </>
  )
}

export default Login
