import { object, string, ref } from 'yup'

const registrationScheme = object().shape({
  firstName: string()
    .required('firstNameRequired')
    .matches(/^[^\s]+$/, 'firstNameWithoutWhitespace')
    .min(2, 'firstNameMinLength')
    .max(30, 'firstNameMaxLength'),
  lastName: string()
    .required('lastNameRequired')
    .matches(/^[^\s]+$/, 'lastNameWithoutWhitespace')
    .min(2, 'lastNameMinLength')
    .max(30, 'lastNameMaxLength'),
  nickname: string()
    .required('nicknameRequired')
    .matches(/^[^\s]+$/, 'nicknameNameWithoutWhitespace')
    .min(4, 'nicknameMinLength')
    .max(15, 'nicknameMaxLength'),
  email: string()
    .required('emailRequired')
    .email('emailValidate')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, 'emailWithoutWhitespace'),
  password: string()
    .required('passwordRequired')
    .min(6, 'passwordMinLength')
    .max(20, 'passwordMaxLength')
    .matches(
      /^(?=(?:.*[A-Z]){1,})(?=(?:.*[a-z]){1,})(?=(?:.*\d){1,})(?=(?:.*[!@#$%^&*().\-_=+{};:,<.>]){1,})([A-Za-z0-9!@#$%^&*()\-_=+{};:,<.>]{6,20})/,
      'passwordValidate'
    )
    .test('passwords-match', 'passwordMustMatch', function (value) {
      if (this.parent.passwordConfirm) {
        return this.parent.passwordConfirm === value
      }

      return true
    }),
  passwordConfirm: string()
    .required('passwordConfirmRequired')
    .oneOf([ref('password')], 'passwordMustMatch'),

  gender: string().required(),
})

export default registrationScheme
